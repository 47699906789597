// registration-page styles
.registration-page {

    background-color: #d2ab71;

    padding-top: 22px;

    .breadcrumbs a {
        color: $dark-grey;
    }


    &__title {
        font-size: calc-em(48px);
        font-weight: $bold;
        color: $dark-grey;
        font-family: $eb;
    }

    &__details {
        margin-bottom: 60px;

        display: flex;
        flex-direction: column;

        font-weight: $bold;

        color: $dark-grey;

        &--title {
            margin:0;
            margin-bottom:5px;
            font-weight: $medium;
            font-size: calc-em(26px);
        }


        &--subtitle {
            margin: 0;

            font-size: calc-em(16px);

            font-weight: $regular;


            span {
                color: red;
            }

        }

    }


    &__form {
        color: $dark-grey;

        input:not([type="checkbox"]) {
            @include input-design;

            border: none;
            border-bottom: 1px solid $dark-grey;

            min-height: 0;

            padding-top: 10px;
            padding-bottom: 10px;

            padding-left: 0;

            width: 100%;
        }

        &--thirds {
            @include clearfix;

            justify-content: space-between;
            margin: 0 -20px 30px;
            .registration-page__form--input {
                display: inline-block;
                float: left;
                width: calc(100% / 3);
                padding: 0 20px;
                > label {
                    font-weight: $semibold;
                    margin-bottom: 10px;

                }
            }

        }

        &--full {
            padding-bottom: 30px;
            display: flex;
    align-items: center;

            input[type="checkbox"] {
                height: 18px;
                width: 18px;
                margin: 0;
            }

            label {
                // margin-left: 8px;
            }

            .registration-page__form--input {
                align-items: normal;
                width: 100%;

                > label {
                    font-weight: $semibold;
                    margin-bottom: 10px;

                }
            }

            >label {
                font-size: calc-em(16px);
                margin-left:8px;
                
            }
        }


        &--input {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            // justify-content: flex-end;
            align-items: center;
            font-size: calc-em(16px);
            font-weight: $medium;
        }


        &--checkbox {
            display: flex;
            flex-direction: row;

            margin-bottom: 30px;

            input[type="checkbox"] {
                height: 18px;
                width: 18px;
                margin: 0;
            }

            &.is-column {
                flex-direction: column;

                > label {
                    margin-bottom: 18px;
                }
            }

            &.is-column-3 {
                .registration-page__form--input {
                    width: 33.33333%;
                    margin-right: 0;
                }
            }

            .registration-page__form--input {
                flex-direction: row;

                margin-right: 40px;

                label {
                    margin-left: 8px;

                    display: inline-block;
                }
            }

            > label {
                min-width: 150px;
                font-weight: $semibold;
            }

            input:not([type="checkbox"]) {
                width:  auto;
            }
        }


        &--checkboxes {
            display: flex;
            flex-direction: row;

            flex-wrap: wrap;


            .registration-page__form--input {
                // flex-basis: 50%;

                // display: flex;
                // justify-content: center;

                margin-bottom: 15px;

                margin-right: 25px;
            }
        }

        #registrationSubmit {
            @include button-design;
            min-width: 160px;
            font-size: calc-em(16px);
            justify-content: center;
        }

    }

    hr {
        border-top: 1px solid $dark-grey;
        outline: 0;
        border-bottom: 0;
        margin: 0 0 25px;
    }

}


@include mobile {

    .registration-page {
        &__details {
            margin-bottom: 30px;
        }
        &__form {
            padding-bottom: 45px;

            #home {
                width: 100%;

                
            }

            #hp {
                width: 100%;
            }

            &--input {
                flex-basis: 100% !important;
                font-size: calc-em(18px);
            }

            input:not([type="checkbox"]) {
                margin-bottom: 0;
            }

            &--full {
                padding-bottom: 0;
                margin-bottom: 25px;
                >label {
                    font-size: calc-em(18px);
                }

                .registration-page__form--input > label {
                    margin-bottom: 0;
                }
            }

            &--thirds {
                margin: 0 -10px 0;

                .registration-page__form--input {
                    padding: 0 10px;
                    width: 100%; 
                    margin-bottom: 25px;
                }
                
            }

            // handle checkboxes
            &--checkbox {
                display: flex;
                flex-wrap: wrap;

                label {
                    flex-basis: 100%;
                    // margin-bottom: 15px;
                    
                }

                >label {
                    font-size: calc-em(18px);
                    margin-bottom: 18px;
                }

                // each
                .registration-page__form--input {
                    flex-basis: 50% !important;
                    margin-right: 0;

                    // margin-bottom: 0;
                }
            }

            #registrationSubmit {
                margin-top: 30px;
                    font-size: calc-em(20px);
            }

        }

    }

}