// about-us styles
.about-us {
  min-height: 470px;

  display: flex;
  flex-direction: column;

  text-decoration: none;

  color: white;

  &__fixed {
    height: 100vh;

    background-image: url("../images/img-about--01.jpg");
    background-size: cover;
    background-position: center center;

    position: fixed;
    top: 50%;
    left: 0;
    right: 0;

    transform: translateY(-50%);

    z-index: -1;

    display: flex;
    align-items: center;
  }

  &__overlay {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: $black0;
    opacity: 0.45;
  }

  &__info {
    color: $white;

    text-align: center;

    &--title {
      font-family: $eb;

      display: flex;
      flex-direction: column;

      align-items: center;

      margin-bottom: 45px;

      h1 {
        font-weight: $regular;

        font-size: calc-em(28px);

        margin: 0;
        margin-bottom: 10px;
      }

      span {
        display: block;

        font-weight: $medium;

        font-size: calc-em(48px);

        line-height: 1;

        margin-bottom: 30px;
      }

      &:after {
        content: "";

        width: 38px;
        height: 2px;

        background-color: $white;

        display: inline-block;
      }
    }

    p {
      margin: 0;

      font-family: $roboto;
      font-weight: $regular;

      max-width: 60%;

      margin-left: auto;
      margin-right: auto;

      margin-bottom: 50px;

      line-height: calc-em(32px);
    }

    a {
      padding: 12px 32px;
    }
  }

  &__title {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;

    &:after {
      content: "";

      width: 50px;
      height: 1px;

      background-color: $black0;

      display: inline-block;
    }

    h1 {
      margin: 0;

      font-family: $eb;
      font-weight: $medium;

      font-size: calc-em(40px);

      color: $dark-grey;

    }
  }
  &__wrap {
    padding: 70px 0 90px;
  }

  &__icons {
    color: $black0;
    text-align: center;

    p {
      font-size: calc-em(18px);
    }
  }

  &__icon {
    padding: 0 15px;
  }

  .two-col {
    &__container {
      display: flex;
      align-items: center;
      background: $off-white;
    }
    &-item {
      width: 60%;

      &:last-child {
        width: 40%;
      }

      &__text {
        padding: 0 25px;
      }

      
    }
  }

  &__content {
    color: $black0;
    text-align: center;
  }

  
}

@include mobile-tablet {
  .about-us {
    min-height: 380px;

    &__info p {
      max-width: 100%;
      padding: 0 20px;
      margin-bottom: 35px;
      font-size: calc-em(20px);
      line-height: 26px;
    }

    &__info--title {
      margin-bottom: 15px;

      span {
        font-size: calc-em(38px);
        line-height: 15px;
        margin-bottom: 25px;
      }
    }
    &__overlay {
      opacity: 0.6;
    }

    &__wrap {
      padding: 35px 0;
    }


    &__icon {
      margin-bottom: 35px;
    }

    .two-col {
      &__container {
        display: block;
        background: $off-white;
      }
      &-item {
        width:100%;
  
        &:last-child {
          width: 100%;
        }

        &__text {
          padding: 25px 25px 35px;
        }
      }
    }

    &__vision {
      .two-col__container {
        background: $off-white;
      }
  
      .about-us__title {
        margin-top: 25px  ;
      }
  
      .two-col-item__text {
        padding-bottom: 25px;
      }
    }
  
  }
}

@include desktop {
  .about-us {
    &__vision {
      .two-col__container {
        display: flex;
        align-items: center;
        background: $off-white;
  
        img {
          display: block;
        }
      }
  
      .two-col-item__text {
        padding: 0 15px;
      }
    }
  }
}
