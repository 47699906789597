// project-container styles
.project-container {
    margin-bottom: 90px;

    &:first-child {
        background-color:$silver;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;

        flex-wrap: wrap;

        justify-content: space-between;


        &.is-reverse {
            flex-direction: row-reverse;
        }

    }

    &__image {
        flex-basis: 53%;

        overflow: hidden;
    }

    &__details {
        flex-basis: 35%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        &--title {
            font-size: calc-em(18px);

            display: flex;
            flex-direction: column;

            color: $dark-grey;

            margin-bottom: 65px;

            span {
                color: $silver;

                padding-top: 10px;

                margin-bottom: 35px;
            }


            &:after {
                content: '';

                width: 45px;
                height: 2px;

                background-color: $gold;


            }

        }

        &--each {
            margin-bottom: 5px;

            label {
                color: $dark-grey
            }

            span {
                color: $silver;
            }

        }

    }

}


@include mobile {

    .project-container {
        margin-bottom: 70px;


        &__wrapper {
            flex-direction: column;

            &.is-reverse {
                flex-direction: column;
            }
        }

        &__image {
            margin-bottom: 40px;
        }

        &__details--title {
            font-size: calc-em(24px);

            span {
                font-size: calc-em(16px);   
            }
        }

    }

}