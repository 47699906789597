// home-banner styles
.home-banner, .microsite-banner {
    color: $white;

    position: relative;
    
    &__details {
        position: absolute;
        left: 0;
        right: 0;
        // bottom: 0;
        top: 60%;
        transform: translateY(-60%);

        margin-bottom: 85px;

        z-index: 1;

        &--title {
            font-family: $eb;
            font-weight: $medium;

            display: flex;
            flex-direction: column;


            h1 {
                font-size: calc-em(72px);

                margin: 0;

                font-weight: $regular;
                display: inline-block;
            }

            span {
                // font-size: calc-em(60px);
                font-weight: $bold;
                line-height: 1;
                display: inline-block;
                margin-left: 15px;
                margin-bottom: 25px;
            }


            &:after {
                content: '';

                width: 50px;
                height: 2px;

                background-color: $white;

                display: inline-block;
            }

        }


        &--desc {
            margin: 45px 0 50px;

            p {
                font-family: $roboto;
                font-weight: $regular;

                margin: 0;

                font-size: calc-em(20px);
            }
        }

    }

    &__each {
        background: black;

        // min-height: 615px;
        height: 100vh;;

        display: flex !important;
        align-items: flex-end;

        background-size: cover;
        background-position: center center;

        position: relative;


        .container {
            width: 100%;

            padding-bottom: 45px;
        }

        &--overlay {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            background-color: rgba(0,0,0,.55);
        }

        &--logo {
            position: absolute;
            z-index: 2;
            right: 20px;
            bottom: 0;
            width: 150px;

            img {
                @include objectfit();
            }
        }

    }

    // ul
    .slick-dots {
        position: absolute;

        left: 0;
        right: 0;
        bottom: 56px;

        display: flex;
        justify-content: center;
        align-items: center;


        li {
            margin: 0 6px;

            display: flex;
            justify-content: flex-start;
            align-items: center;


            button {
                background-color: transparent;
                border: 1px solid transparent;

                width: 14px;
                height: 14px;

                position: relative;

                color: transparent;


                &:before {
                    content: "•";

                    font-size: 4em;

                    color: $white;

                    opacity: 1;

                    cursor: pointer;
                }

            }


            &.slick-active {

                button:before {
                    color: $gold;
                }

            }

        }
    }


}

.microsite-banner {
    background: black;

    &__each {
        color: white;
        text-decoration: none;
        outline: 0;
        border: 0;
        transition: color 800ms ease;
        &--overlay {
            position: absolute;
            top: 0;
            right: 0;
            left: initial;
            bottom: 0;
            width: 100%;
            @include background-gradient(transparent, black, horizontal);
        }

        &:hover {
            color: $gold2;
        }
    }

    &__details {
        width: 32%;
        left: initial;
        right: 10%;
        text-align: right;

        &--title {

            h1 {
                font-size: calc-em(42px);
            }

            &:after {
                display: none;
            }
        }

        &--desc {
            margin: 0 0 30px;
            p {
                font-size: calc-em(18px);
                line-height: 30px;
            }
        }
    }

    .slick-dots {
        position: absolute;
        right: 30px;
        top: 50%;
        bottom: initial;
        left: initial;
        display: block;
        // justify-content: center;
        // align-items: center;

        li {
            display:block;
            margin: 0;

            button {
                width: auto;
                height: auto;
                margin-bottom: 10px;
            }

            button:before {
                content: '';
                width: 14px;
                height: 14px;
                background: white;
                display: inline-block;
                border-radius: 30px;

            }

            &.slick-active {
                button:before {
                    background: $gold2;
                }
            }
        }
    }
    .slick-vertical .slick-slide {
        border: 0;
    }

    .slick-slide {
        opacity:0;
        transition:opacity 0.8s ease-out;
     }
     
     .slick-active {
         opacity:1!important;
         transition:opacity 0.8s ease-in;
         }
     
     .slick-cloned {
         opacity: 0.3;
     }
}

@include mobile-tablet {

    .home-banner, .microsite-banner {


        &__details {
            text-align: center;
            .container {
                padding: 0 20px;
            }

            &--desc {
                margin: 15px 0 25px;
            }

            &--title h1 {
                font-size: calc-em(52px);
            }

            &--title {
                align-items: center;
            }

        }

        &__each {
            &--logo {
                position: relative;
                z-index: initial;
                right: initial;
                bottom: initial;
                width: 100px;
                margin: 0 auto 10px;
            }
        }

    }

    .microsite-banner {

        &__details {
            top: initial;
            bottom: 0;
            transform: none;
            margin-bottom: 50px;
            width: 100%;
            right: 0;

            &--title:after {
                display: block;
            }
        }

        &__each {
            &--overlay {
                position: absolute;
                top: initial;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                @include background-gradient(black, transparent, vertical);
            }
        }

        .slick-dots {
            right: 0px;
        }
        
    }

}


@include mobile {

    .home-banner, .microsite-banner {
        height: 70vh;

        &__each {
            height: 70vh;
        }
    }
    
}
