// project-about styles
.project-about {
  padding-top: 74px;
  padding-bottom: 100px;

  background: $off-white;

  > .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex-wrap: wrap;
  }

  &__display {
    // flex-basis: 40%;
    width: 40%;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    padding-bottom: 45px;
    
    color: $dark-grey;


    &--top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    

    &--text {
        margin: 0;
    }

    &--title {
      margin: 0;

      font-family: $eb;

      font-size: calc-em(36px);

      display: flex;
      flex-direction: column;

        margin-bottom: 25px;


        &:after {
            content: '';
            width: 60%;

            height: 1px;
            
            background-color: $dark-grey;

            margin-top: 25px;

        }

    }
  }

  &__more {


    &--count {
        margin-left: 22px;

        font-size: calc-em(30px);

        font-family: $eb;
    }

  }

  &__slick {
    width: 55%;

    .slick-list {
      padding: 0 35% 0 0 !important;
    }
  }

  &__image {
    width: 340px;
    height: 360px;
  }
}

@include mobile-tablet {
  .project-about {
    > .container {
      flex-direction: column;
    }

    &__each {
        overflow: hidden;
    }
    
    &__more {
        margin-top: 30px;
    }

    &__display {
      width: 100%;

      margin-bottom: 15px;
    }

    &__slick {
      width: 100%;

      .slick-list {
        padding: 0 !important;
      }

      /* the slides */
      .slick-slide {
        margin: 0 10px;
      }
      /* the parent */
      .slick-list {
        margin: 0 -10px;
      }
    }
  }
}

@include mobile {

  .project-about {
    padding-top: 36px;
  }

}

@media (max-width: 550px) {

    .project-about__slick .slick-list {
        padding: 0 10% 0 0 !important;
    }

}