// site-footer styles
.site-footer {
    background-color: $dark-grey;

    color: $white;

    padding: 45px 0 26px;

    font-family: $roboto;
    font-weight: $regular;
    // font-size: calc-em(14px);

    &__microsite {
        background: $darkblue;

        .back-to-top {
            .icon {
                background: $lightblue2;
            }

            &:hover .icon {
                background: $gold;
                color: white;
            }
        }

        .site-footer__column a:hover {
            color: $gold;
        }
    }

    a {
        color: $white;

        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

    }


    &__column {
        margin-bottom: 20px;
        position: relative;

        &--row {

            margin-bottom: 23px;

            ul {
                display: flex;

                li {
                    padding-right: 15px;
                }
            }

        }

        &--social {
            display: flex;
            justify-content: flex-end;

            i {
                color: $white;
                transition: color .35s ease-in-out;

                font-size: 1.5em;
            }

            a {
                margin-right: 20px;
                
                &:hover {
                    text-decoration: none;

                    i {
                        color: $gold;

                        transition: color .35s ease-in-out;
                    }

                }
                

            }

        }
            
        h2 {
            margin: 0;

            font-family: $eb;
            font-weight: $regular;
            
            font-size: calc-em(18px);

            margin-bottom: 12px;

        }

        span,
        a {
            font-family: $roboto;

            color: #a0a0a0;

            line-height: calc-em(22px);

            font-size: calc-em(14px);

            text-decoration: none;
        }

        a {
            margin-bottom: 12px;

            display: block;
        }

    }


    &__details {
        display: flex;
        flex-direction: row;

        justify-content: flex-start;

        // margin-bottom: 50px;


        &--each {
            // display: flex;
            // flex-direction: column;

            // justify-content: space-between;

            // margin-right: 12%;
            width: 100%;
        }

        &__each {
            width: 100%;
        }

    }

    &__bottom {
        border-top: 1px solid rgba(255, 255, 255, 0.5);

        padding-top: 18px;

        p {
            margin: 0;

            font-size: calc-em(12px);

            color: #a0a0a0;
        }

    }

    &__logo {
        position: absolute;
        right: 0;
        top: 0;
    }

}


@include mobile-tablet {

    .site-footer {
        font-size: calc-em(22px);


        &__details {
            justify-content: space-between;

            margin-bottom: 15px;
            

            &--each {
                margin-right: 0;
                width: 100%;
            }

            

        }

        &__column {
            margin-bottom: 25px;

            &--social {
                justify-content: flex-start;
            }

            &--row {
                justify-content: space-between;
            }
        }

        &__logo {
            position: relative;
            text-align: right;
        }

        &__microsite {
            padding: 25px 0 15px;
            .site-footer__column--row {
                margin-bottom: 0;
            }

            .site-footer__logo a {
                width: 95px;
                margin: 0 0 0 auto;
            }

            .back-to-top {
                bottom: 50px;
            }
        }
    }

}


@include mobile {

    .site-footer {


        &__details {
            flex-direction: column;

        }

        &__column {
    
            &--row {
                display: flex;
                flex-direction: row;

                ul {
                    display: block;
                }
            }
        }


    }

}