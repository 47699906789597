// related-projects styles
.related-projects {
    background: #ececec;

    padding-top: 45px;
    padding-bottom: 65px;

    &__header {
        font-weight: $regular;

        font-size: calc-em(28px);

        color: $dark-grey;

        margin: 0;
        margin-bottom: 35px;
    }

    &__container {
        display: flex;
        flex-direction: row;

        flex-wrap: wrap;
        margin: 0 -20px;
        // justify-content: space-between;

        .related-card {
            padding: 0 20px;
        }
    }

}


@include mobile {

    .related-projects {

        &__container {
            flex-direction: column;
            margin: 0;

            .related-card {
                padding: 0;
            }
        }

        &__header {
            margin-bottom: 15px;
            font-weight: $bold;
        }

    }

}