// breadcrumbs styles
.breadcrumbs {
    font-weight: $bold;
    a {
        text-decoration: none;
        color: $dark-grey;
        font-weight: $regular;
        position: relative;
        margin-right: 15px;
        transition: color 500ms ease;
        &:hover, &:focus {
            text-decoration: underline;
            color: $gold;
            &:after {
                color: $dark-grey;
            }
        }
        &:after {
            content: '/';
            padding: 0 4px;
            position: absolute;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

@include mobile-tablet {
    .breadcrumbs {
        a, span {
            font-size: calc-em(18px);
        }
    }
}