// Normalize Styles


// Import Node Module Styles
@import 'node_modules/slick-carousel/slick/slick.scss';

// Fonts
@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

// Import Common
@import 'common/mixins';
@import 'common/config';
@import 'common/grids';
@import 'common/icons';
@import 'common/helpers';

// Vendor
@import './vendor/_jquery.fancybox';

// Import pages
@import 'pages/about';
@import 'pages/project';


// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* =======================================================================
01 Global
========================================================================== */

@-ms-viewport {
	width: auto;
}

html {
	font-size: 1em;
	overflow-x: hidden;

	font-family: $roboto;

}

body {
	// font-size: 1em; //16px
	font: 1em/1.3 $roboto;
	// position: relative;

	width: 100%;
	margin: 0;
  padding: 0;

}


%plainlist {
	@include plainlist();
}

ul {
	@extend %plainlist;
}


img {
	max-width: 100%;

	vertical-align: initial;
}

// Styling on warning for users on IE7 or below
.browsehappy {
	margin: 0;
	padding: .5em 0;

	text-align: center;

	color: #000;
	background: #fff8e2;
}


/* =======================================================================
02 Layout
========================================================================== */

%container {
	position: relative;

	max-width: 1280px;
	margin: 0 auto;
	padding: 0 20px;
}

.container {
	margin: 0 auto;
  
	
  
	@extend %container;
  
	@include desktop {
	//   max-width: 1020px;
	}
  
	@include mobile-tablet {
	//   max-width: 760px;
	}
  
	@include mobile {
	//   max-width: 580px;
  
	  padding: 0 15px;
	}
  
  }


main {
	min-height: 500px;
}

.standard-content {
	padding: 30px 0;

	@include mobile {
		padding: 15px 0;
	}

	&__gray {
		background: $off-white;
		padding: 30px 0;
	}
}

.text-center {
	text-align: center;
}

.text-secondary-font {
	font-family: EB Garamond, serif;
}

.text-gray {
	color: $silver;
}

.text-gold {
	color: $gold;
}

// .fancybox-slide--iframe .fancybox-content {
//     width  : 800px;
//     height : 600px;
//     max-width  : 80%;
//     max-height : 80%;
//     margin: 0;
// }

/* =======================================================================
03 Modules
========================================================================== */
@import '../_modules/atoms/back-to-top/back-to-top';
@import '../_modules/atoms/button/button';
@import '../_modules/atoms/breadcrumbs/breadcrumbs';
@import '../_modules/atoms/related-card/related-card';
@import '../_modules/atoms/list/list';
@import '../_modules/atoms/sorter/sorter';
@import '../_modules/atoms/custom-select/custom-select';

@import '../_modules/molecules/navigation/navigation';
@import '../_modules/molecules/gallery/gallery';
@import '../_modules/molecules/img-box/img-box';
@import '../_modules/molecules/timeline/timeline';
@import '../_modules/molecules/form-modal/form-modal';

@import '../_modules/organisms/site-header/site-header';
@import '../_modules/organisms/site-footer/site-footer';
@import '../_modules/organisms/about-us/about-us';
@import '../_modules/organisms/latest-projects/latest-projects';
@import '../_modules/organisms/home-banner/home-banner';
@import '../_modules/organisms/latest-news/latest-news';
@import '../_modules/organisms/standard-banner/standard-banner';
@import '../_modules/organisms/registration-page/registration-page';

@import '../_modules/organisms/project-container/project-container';
@import '../_modules/organisms/project-about/project-about';
@import '../_modules/organisms/related-projects/related-projects';
@import '../_modules/organisms/featured-projects/featured-projects';
@import '../_modules/organisms/happenings/happenings';
@import '../_modules/organisms/home-modal/home-modal';
@import '../_modules/organisms/home-carousel-modal/home-carousel-modal';
@import '../_modules/organisms/completedproject/completedproject';
@import '../_modules/organisms/career-listing/career-listing';
@import '../_modules/organisms/career-detail/career-detail';

// contact-us page
@import '../_modules/organisms/location-map/location-map';
@import '../_modules/molecules/contact-details/contact-details';
@import '../_modules/molecules/contact-form/contact-form';

/*========================================================================
>> Common for all the pages
========================================================================== */


button,
input,
select {
	&:focus {
		outline: none;
	}
}

.object-fit {
	width: 100%;
	height: 100%;

	object-fit: cover;
}


/* =======================================================================
04 Mediaqueries
========================================================================== */

@include mobile-tablet {

	html {
		font-size: .875em;
	}

	body {
		font-size: .875em;
	}

}