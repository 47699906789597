// location-map styles
.location-map {
    padding-top: 70px;
    padding-bottom: 100px;

    &__map {
        margin-bottom: 35px;
    }

    &__header {
        font-weight: $bold;

        font-size: calc-em(28px);

        margin: 0;
        margin-bottom: 20px;
    }

    &__address {
        display: flex;
        align-items: center;

        color: $dark-grey;

        .icon {
            font-size: 2.55em;

            margin-right: 23px;
        }

        &--text {

            h2 {
                margin: 0;
                font-weight: $bold;

                font-size: calc-em(20px);

                margin-bottom: 9px;
            }

            span {
                font-weight: $regular;

                font-size: calc-em(20px);
            }

        }

    }

    &__directions {
        display: flex;

        padding-top: 45px;

        &--each {
            text-decoration: none;

            color: $dark-grey;

            margin-right: 70px;

            img {
                margin-right: 17px;
            }

            &:hover {

                .icon {
                    transform: translateX(7px);

                    transition: transform .5s;
                }

            }

        }

        &--text {
            display: flex;
            align-items: center;

            font-size: calc-em(16px);

            padding-bottom: 9px;

            border-bottom: 1px solid $dark-grey;

            p {
                margin: 0;
            }

            .icon {
                padding-left: 15px;

                transform: translateX(0);
                transition: transform .5s;
            }
        }

    }


    #map {
        width: 100%;

        height: 500px;

        margin-bottom: 30px;
    }

}


@include mobile {

    .location-map {
        padding-top: 30px;
        padding-bottom: 50px;
        &__directions {

            flex-direction: column;
            align-items: flex-start;

            &--each {
                margin-right:0;
                margin-bottom: 10px;
            }

        }

    }

}