// list styles

.list {
    margin: 15px 0 0 0;
    li {
        margin-bottom: 15px; 
    }
    &__item {
        position: relative;
        span {
            width: 35px;
            top: 0;
            left:0;
            display: inline-block;
            position: absolute;
        }
    
        p {
            display: inline-block;
            width: 100%;
            margin: 0;
            padding-left: 35px;
        }

        &-child {
            position: relative;

            span {
                width: 45px;
            }

            p {
                padding-left: 45px;
            }
        }
    }
    &-lower-latin {
        list-style: lower-latin;
    }

    &-nostyle {
        @include normalise-list;
        margin: 15px 0 0 0;
    }

    
}