// completedproject styles

.completedproject {
    margin: 3em 0;
    ul {
        @include clearfix;
    }
    li {
        width: 33.333333%;
        float: left;
        margin-bottom: 35px;
    }

    .happenings__each:hover .happenings__each--content {
        background: transparent;
    }

    .happenings__each:hover .happenings__each--title span {
        color: #a0a0a0;
        opacity: 1;
    }
}
@include mobile-tablet {
    .completedproject {
        li {
            width: 50%;
            float: left;
            margin-bottom: 5px;
        }

        .happenings__each--title span {
            margin-bottom: 15px;
        }

        .happenings__each--inner {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
