@charset "UTF-8";
/* Slider */
@import url("https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@media only screen and (min-width: 320px), print {
  [class^='col-'],
  [class*=' col-'] {
    width: 100%;
    align-items: flex-start;
  }
  .reverse-col-flow [class^='col-'],
  .reverse-col-flow [class*=' col-'] {
    align-items: flex-end;
  }
  .row-col-12 {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
  }
  .row-col-12 .col-1-xs {
    width: 8.3333333333%;
  }
  .row-col-12 .offset-1-xs {
    margin-left: 8.3333333333%;
  }
  .row-col-12 .col-2-xs {
    width: 16.6666666667%;
  }
  .row-col-12 .offset-2-xs {
    margin-left: 16.6666666667%;
  }
  .row-col-12 .col-3-xs {
    width: 25%;
  }
  .row-col-12 .offset-3-xs {
    margin-left: 25%;
  }
  .row-col-12 .col-4-xs {
    width: 33.3333333333%;
  }
  .row-col-12 .offset-4-xs {
    margin-left: 33.3333333333%;
  }
  .row-col-12 .col-5-xs {
    width: 41.6666666667%;
  }
  .row-col-12 .offset-5-xs {
    margin-left: 41.6666666667%;
  }
  .row-col-12 .col-6-xs {
    width: 50%;
  }
  .row-col-12 .offset-6-xs {
    margin-left: 50%;
  }
  .row-col-12 .col-7-xs {
    width: 58.3333333333%;
  }
  .row-col-12 .offset-7-xs {
    margin-left: 58.3333333333%;
  }
  .row-col-12 .col-8-xs {
    width: 66.6666666667%;
  }
  .row-col-12 .offset-8-xs {
    margin-left: 66.6666666667%;
  }
  .row-col-12 .col-9-xs {
    width: 75%;
  }
  .row-col-12 .offset-9-xs {
    margin-left: 75%;
  }
  .row-col-12 .col-10-xs {
    width: 83.3333333333%;
  }
  .row-col-12 .offset-10-xs {
    margin-left: 83.3333333333%;
  }
  .row-col-12 .col-11-xs {
    width: 91.6666666667%;
  }
  .row-col-12 .offset-11-xs {
    margin-left: 91.6666666667%;
  }
  .row-col-12 .col-12-xs {
    width: 100%;
  }
  .row-col-12 .offset-12-xs {
    margin-left: 100%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333%;
  }
  .push-left-5 {
    padding-left: 5px;
  }
  .push-left-10 {
    padding-left: 10px;
  }
  .push-left-15 {
    padding-left: 15px;
  }
  .push-left-20 {
    padding-left: 20px;
  }
  .push-left-25 {
    padding-left: 25px;
  }
  .push-right-5 {
    padding-right: 5px;
  }
  .push-right-10 {
    padding-right: 10px;
  }
  .push-right-15 {
    padding-right: 15px;
  }
  .push-right-20 {
    padding-right: 20px;
  }
  .push-right-25 {
    padding-right: 25px;
  }
}

@media only screen and (min-width: 768px), print {
  .row-col-12 .col-1-sm {
    width: 8.3333333333%;
  }
  .row-col-12 .offset-1-sm {
    margin-left: 8.3333333333%;
  }
  .row-col-12 .col-2-sm {
    width: 16.6666666667%;
  }
  .row-col-12 .offset-2-sm {
    margin-left: 16.6666666667%;
  }
  .row-col-12 .col-3-sm {
    width: 25%;
  }
  .row-col-12 .offset-3-sm {
    margin-left: 25%;
  }
  .row-col-12 .col-4-sm {
    width: 33.3333333333%;
  }
  .row-col-12 .offset-4-sm {
    margin-left: 33.3333333333%;
  }
  .row-col-12 .col-5-sm {
    width: 41.6666666667%;
  }
  .row-col-12 .offset-5-sm {
    margin-left: 41.6666666667%;
  }
  .row-col-12 .col-6-sm {
    width: 50%;
  }
  .row-col-12 .offset-6-sm {
    margin-left: 50%;
  }
  .row-col-12 .col-7-sm {
    width: 58.3333333333%;
  }
  .row-col-12 .offset-7-sm {
    margin-left: 58.3333333333%;
  }
  .row-col-12 .col-8-sm {
    width: 66.6666666667%;
  }
  .row-col-12 .offset-8-sm {
    margin-left: 66.6666666667%;
  }
  .row-col-12 .col-9-sm {
    width: 75%;
  }
  .row-col-12 .offset-9-sm {
    margin-left: 75%;
  }
  .row-col-12 .col-10-sm {
    width: 83.3333333333%;
  }
  .row-col-12 .offset-10-sm {
    margin-left: 83.3333333333%;
  }
  .row-col-12 .col-11-sm {
    width: 91.6666666667%;
  }
  .row-col-12 .offset-11-sm {
    margin-left: 91.6666666667%;
  }
  .row-col-12 .col-12-sm {
    width: 100%;
  }
  .row-col-12 .offset-12-sm {
    margin-left: 100%;
  }
}

@media only screen and (min-width: 1024px), print {
  .row-col-12 .col-1-md {
    width: 8.3333333333%;
  }
  .row-col-12 .offset-1-md {
    margin-left: 8.3333333333%;
  }
  .row-col-12 .col-2-md {
    width: 16.6666666667%;
  }
  .row-col-12 .offset-2-md {
    margin-left: 16.6666666667%;
  }
  .row-col-12 .col-3-md {
    width: 25%;
  }
  .row-col-12 .offset-3-md {
    margin-left: 25%;
  }
  .row-col-12 .col-4-md {
    width: 33.3333333333%;
  }
  .row-col-12 .offset-4-md {
    margin-left: 33.3333333333%;
  }
  .row-col-12 .col-5-md {
    width: 41.6666666667%;
  }
  .row-col-12 .offset-5-md {
    margin-left: 41.6666666667%;
  }
  .row-col-12 .col-6-md {
    width: 50%;
  }
  .row-col-12 .offset-6-md {
    margin-left: 50%;
  }
  .row-col-12 .col-7-md {
    width: 58.3333333333%;
  }
  .row-col-12 .offset-7-md {
    margin-left: 58.3333333333%;
  }
  .row-col-12 .col-8-md {
    width: 66.6666666667%;
  }
  .row-col-12 .offset-8-md {
    margin-left: 66.6666666667%;
  }
  .row-col-12 .col-9-md {
    width: 75%;
  }
  .row-col-12 .offset-9-md {
    margin-left: 75%;
  }
  .row-col-12 .col-10-md {
    width: 83.3333333333%;
  }
  .row-col-12 .offset-10-md {
    margin-left: 83.3333333333%;
  }
  .row-col-12 .col-11-md {
    width: 91.6666666667%;
  }
  .row-col-12 .offset-11-md {
    margin-left: 91.6666666667%;
  }
  .row-col-12 .col-12-md {
    width: 100%;
  }
  .row-col-12 .offset-12-md {
    margin-left: 100%;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/fonts/icomoon.eot?ybxbmr");
  src: url("../fonts/fonts/icomoon.eot?ybxbmr#iefix") format("embedded-opentype"), url("../fonts/fonts/icomoon.ttf?ybxbmr") format("truetype"), url("../fonts/fonts/icomoon.woff?ybxbmr") format("woff"), url("../fonts/fonts/icomoon.svg?ybxbmr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e906";
}

.icon-location:before {
  content: "\e947";
}

.icon-arrow-right2:before {
  content: "\ea3c";
}

.icon-arrow-left2:before {
  content: "\ea40";
}

.icon-facebook2:before {
  content: "\ea91";
}

.icon-whatsapp:before {
  content: "\ea93";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-angle-up:before {
  content: "\e900";
}

.icon-angle-right:before {
  content: "\e901";
}

.icon-angle-left:before {
  content: "\e902";
}

.icon-arrow-right:before {
  content: "\e903";
}

.icon-arrow-left:before {
  content: "\e904";
}

.icon-angle-down:before {
  content: "\e905";
}

.sorter__options ul, .custom-select__custom-dropdown .custom-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@media (min-width: 1024px) {
  .no-desktop {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .no-mobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .desktop-only {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .handheld-only {
    display: none !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

@media (max-width: 767px) {
  .no-padding--mbl {
    padding: 0 !important;
  }
  .no-padding-right--mbl {
    padding-right: 0 !important;
  }
  .no-padding-left--mbl {
    padding-left: 0 !important;
  }
  .no-padding-top--mbl {
    padding-top: 0 !important;
  }
  .no-padding-bottom--mbl {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .no-padding--tbt {
    padding: 0 !important;
  }
  .no-padding-right--tbt {
    padding-right: 0 !important;
  }
  .no-padding-left--tbt {
    padding-left: 0 !important;
  }
  .no-padding-top--tbt {
    padding-top: 0 !important;
  }
  .no-padding-bottom--tbt {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1024px) {
  .no-padding--dsk {
    padding: 0 !important;
  }
  .no-padding-left--dsk {
    padding-left: 0 !important;
  }
  .no-padding-right--dsk {
    padding-right: 0 !important;
  }
  .no-padding-top--dsk {
    padding-top: 0 !important;
  }
  .no-padding-bottom--dsk {
    padding-bottom: 0 !important;
  }
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

@supports (padding: 0px) {
  .fancybox-caption {
    padding: 75px max(44px, env(safe-area-inset-right)), max(25px, env(safe-area-inset-bottom)), max(44px, env(safe-area-inset-left));
  }
}

.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
  @supports (padding: 0px) {
    .fancybox-caption {
      padding-left: max(12px, env(safe-area-inset-left));
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
}

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}

.about-us__intro {
  padding: 80px 0;
}

.about-us__intro .container {
  display: flex;
  align-items: flex-start;
}

.about-us__intro .container img {
  margin-left: 30px;
  max-width: 230px;
}

.about-us__intro--paragraphs {
  padding-left: 55px;
}

.about-us__intro--paragraphs p {
  margin-top: 0;
  font-size: 1.125em;
  line-height: 1.77;
  color: #333333;
}

.about-us__background {
  min-height: 500px;
  position: relative;
}

.about-us__background img {
  position: absolute;
}

.about-us__container {
  padding-top: 58px;
  padding-bottom: 10px;
}

.about-us__container--right h2 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  font-size: 1.375em;
  color: #333333;
  margin-bottom: 12px;
}

.about-us__container--right p {
  margin: 0;
  font-weight: 300;
  font-size: 1.125em;
  color: #a0a0a0;
  margin-bottom: 32px;
}

.about-us__container > .container {
  display: flex;
}

.about-us__container h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  color: #333333;
  font-size: 1.5em;
  width: 45%;
  margin-right: 105px;
}

.about-us__select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 45px;
}

.about-us__select .icon {
  border: none;
  background-color: transparent;
  font-size: 2em;
  color: #333333;
  cursor: pointer;
}

.about-us__select .icon:hover {
  color: #b99c66;
}

.about-us__select .icon:last-of-type {
  position: relative;
  transform: rotate(180deg);
}

.about-us__select a {
  color: #b99c66;
  padding: 7px 5px;
  background-color: transparent;
  font-size: 0.880625em;
  text-decoration: none;
  border-radius: 14px;
  white-space: nowrap;
  transition: background-color .5s, color .5s;
  margin-bottom: 11px;
}

.about-us__select a:hover, .about-us__select a.active {
  background-color: #b99c66;
  color: #ffffff;
  transition: background-color .5s, color .5s;
}

.about-us__milestones {
  padding-top: 72px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
}

.about-us__milestones > .container {
  display: flex;
  flex-direction: row;
}

.about-us__milestone {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.about-us__milestone.show {
  visibility: visible;
  opacity: 1;
  max-height: 2000px;
  transition: opacity .4s ease-in;
}

.about-us__milestone--image {
  height: 620px;
}

.about-us__milestone--details {
  display: flex;
  padding-top: 25px;
}

.about-us__milestone--left {
  border-right: 1px solid #333333;
  padding-right: 34px;
}

.about-us__milestone--left h2 {
  font-family: "EB Garamond", serif;
  color: #333333;
  margin: 0;
  font-family: 600;
  font-size: 2em;
}

.about-us__milestone--left p {
  margin: 0;
  color: #b99c66;
  font-family: 700;
  font-size: 1.375em;
}

.about-us__milestone--right {
  padding-left: 64px;
}

.about-us__milestone--right p {
  margin: 0;
  font-weight: 300;
  font-size: 1.25em;
  color: #333333;
}

@media (max-width: 767px) {
  .about-us__intro .container {
    flex-direction: column;
  }
  .about-us__intro .container img {
    margin-left: 0;
    margin-bottom: 40px;
  }
  .about-us__intro--paragraphs {
    padding-left: 0;
  }
  .about-us__background {
    position: relative;
    padding-top: 56.25%;
    min-height: 0;
  }
  .about-us__background img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .about-us__container h1 {
    margin-bottom: 40px;
  }
  .about-us__container > .container {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .about-us__select {
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-us__milestones > .container {
    flex-direction: column-reverse;
  }
  .about-us__milestone--left {
    border-right: 0;
    padding-right: 0;
    padding-bottom: 20px;
  }
  .about-us__milestone--right {
    padding-left: 0;
  }
  .about-us__milestone--details {
    flex-direction: column;
  }
  .about-us__milestone--image {
    height: auto;
  }
}

.project-about-us .about-us__container h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.project-about-us .about-us__container--right h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.project-about-us .about-us__container > .container {
  margin-top: 30px;
  margin-bottom: 30px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* =======================================================================
01 Global
========================================================================== */
@-ms-viewport {
  width: auto;
}

html {
  font-size: 1em;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

body {
  font: 1em/1.3 "Roboto", sans-serif;
  width: 100%;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
  vertical-align: initial;
}

.browsehappy {
  margin: 0;
  padding: .5em 0;
  text-align: center;
  color: #000;
  background: #fff8e2;
}

/* =======================================================================
02 Layout
========================================================================== */
.container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.container {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

main {
  min-height: 500px;
}

.standard-content {
  padding: 30px 0;
}

@media (max-width: 767px) {
  .standard-content {
    padding: 15px 0;
  }
}

.standard-content__gray {
  background: #f9f9f9;
  padding: 30px 0;
}

.text-center {
  text-align: center;
}

.text-secondary-font {
  font-family: EB Garamond, serif;
}

.text-gray {
  color: #a0a0a0;
}

.text-gold {
  color: #b99c66;
}

/* =======================================================================
03 Modules
========================================================================== */
.back-to-top {
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  position: fixed;
  right: 30px;
  bottom: 30px;
  transition: opacity .5s ease-in-out;
  z-index: 6;
}

.back-to-top img {
  width: 70px;
}

.back-to-top .icon {
  background: #b99c66;
  border-radius: 40px;
  padding: 10px 21px 12px 20px;
  font-size: 30px;
  display: inline-block;
  transition: all 500ms ease;
}

.back-to-top .icon:before {
  transform: rotate(-90deg);
  display: inline-block;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s ease-in-out;
}

.back-to-top:hover .icon {
  color: #b99c66;
  background: #000000;
}

@media (max-width: 767px) {
  .back-to-top {
    right: 20px;
    bottom: 60px;
  }
  .back-to-top .icon {
    padding: 10px 16px;
    font-size: 21px;
    line-height: 20px;
  }
}

.button {
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 50px;
  min-width: 200px;
  display: inline-block;
  border: 2px solid #ffffff;
}

.button span {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 0.875em;
}

.button__opaque {
  background-color: #b99c66;
  border: none;
  transition: filter .5s;
}

.button__opaque:hover {
  filter: brightness(1.1);
  transition: filter .5s;
}

.button__dark {
  background-color: #333333;
  border: none;
  transition: filter .5s;
}

.button__dark:hover {
  filter: brightness(1.1);
  transition: filter .5s;
}

@media (max-width: 1023px) {
  .button {
    min-width: auto;
    padding: 15px 40px;
  }
  .button span {
    font-size: 1em;
  }
}

.breadcrumbs {
  font-weight: 700;
}

.breadcrumbs a {
  text-decoration: none;
  color: #333333;
  font-weight: 300;
  position: relative;
  margin-right: 15px;
  transition: color 500ms ease;
}

.breadcrumbs a:hover, .breadcrumbs a:focus {
  text-decoration: underline;
  color: #b99c66;
}

.breadcrumbs a:hover:after, .breadcrumbs a:focus:after {
  color: #333333;
}

.breadcrumbs a:after {
  content: '/';
  padding: 0 4px;
  position: absolute;
}

.breadcrumbs a:last-child:after {
  display: none;
}

@media (max-width: 1023px) {
  .breadcrumbs a, .breadcrumbs span {
    font-size: 1.125em;
  }
}

.related-card {
  flex-basis: calc(33% - 17.5px);
  text-decoration: none;
  color: #333333;
}

.related-card__details--title {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.related-card__details--desc {
  margin: 0;
  font-size: 1em;
  color: #a0a0a0;
}

.related-card__image {
  overflow: hidden;
  margin-bottom: 13px;
}

.related-card__image img {
  transform: scale(1);
  transition: transform .5s;
}

.related-card:hover .related-card__image img {
  transform: scale(1.2);
  transition: transform .5s;
}

@media (max-width: 767px) {
  .related-card {
    flex-basis: 100%;
    margin-bottom: 25px;
  }
  .related-card:last-of-type {
    margin-bottom: 0;
  }
  .related-card__image {
    margin-bottom: 10px;
  }
  .related-card__details--title {
    font-size: 1.5em;
  }
}

.list {
  margin: 15px 0 0 0;
}

.list li {
  margin-bottom: 15px;
}

.list__item {
  position: relative;
}

.list__item span {
  width: 35px;
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
}

.list__item p {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding-left: 35px;
}

.list__item-child {
  position: relative;
}

.list__item-child span {
  width: 45px;
}

.list__item-child p {
  padding-left: 45px;
}

.list-lower-latin {
  list-style: lower-latin;
}

.list-nostyle {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 15px 0 0 0;
}

@keyframes popup {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes popupClose {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0);
  }
}

.sorter__options {
  position: relative;
}

.sorter__options button {
  width: 100% !important;
  font-size: 1em;
  line-height: 18px;
  background-color: transparent !important;
  border: 1px solid #000000 !important;
  padding: 15px 25px 15px 17px !important;
  position: relative;
  text-align: left;
  cursor: pointer;
  border-radius: 0 !important;
  letter-spacing: -0.2px;
}

.sorter__options button span {
  color: #000000 !important;
}

.sorter__options button i {
  display: inline-block;
  position: absolute;
  font-size: 1.625em !important;
  top: 15px;
  right: 10px;
  color: #000000 !important;
}

.sorter__options button:hover i {
  transform: none !important;
  transition: none !important;
}

.sorter__options > ul {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.sorter__options ul {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 2;
  transform-origin: 50% 5%;
  transform: scale(0);
  max-height: 270px;
  overflow-y: auto;
}

.sorter__options ul::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sorter__options ul::-webkit-scrollbar:vertical {
  width: 3px;
}

.sorter__options ul::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

.sorter__options ul::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #000000;
}

.sorter__options ul.opened {
  animation: popup 0.25s ease-out 0s 1;
  transform: scale(1);
}

.sorter__options ul.closed {
  animation: popupClose 0.25s ease-out 0s 1;
  transform: scale(0) !important;
}

.sorter__options li {
  display: block;
}

.sorter__options li a, .sorter__options li button, .sorter__options li span {
  width: 100%;
  display: block;
  font-size: 1em;
  line-height: 18px;
  background-color: #333333;
  padding: 16px 20px;
  margin: 0;
  color: #ffffff;
  border: none;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
}

.sorter__options li a:hover, .sorter__options li button:hover, .sorter__options li span:hover {
  background-color: #f2f2f2;
  color: 900;
}

.sorter {
  margin: 0 0 30px;
}

.sorter > div {
  display: block;
}

.sorter > div > * {
  width: 100%;
}

.sorter__label {
  font-size: 1.125em;
  font-weight: normal;
  margin-right: 15px;
  letter-spacing: -0.2px;
  margin-bottom: 13px;
  display: block;
}

@media only screen and (min-width: 768px) {
  .sorter {
    margin: 0;
  }
  .sorter__label {
    font-size: 0.9375em;
    margin-right: 23px;
  }
  .sorter__options a, .sorter__options button {
    width: 300px;
    font-size: 0.9375em;
  }
  .sorter__options a {
    font-size: 0.9375em;
  }
  .sorter__options ul {
    width: 100%;
  }
}

.custom-select label {
  margin-bottom: 13px;
  display: block;
}

.custom-select__label {
  color: #333333;
  font-size: 0.875em;
  font-weight: 500;
  margin-right: 10px;
}

.custom-select select {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.custom-select__custom-dropdown {
  display: block;
  position: relative;
}

.custom-select__custom-dropdown.list-shown .custom-list {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

.custom-select__custom-dropdown .custom-trigger {
  background-color: transparent;
  border: 1px solid #000000;
  padding: 15px 25px 15px 17px;
  border-radius: 0;
  color: #000000;
  font-size: 1em;
  position: relative;
  text-align: left;
  width: 100%;
}

.custom-select__custom-dropdown .custom-trigger:hover, .custom-select__custom-dropdown .custom-trigger:focus {
  background: #d2ab71;
}

.custom-select__custom-dropdown .custom-trigger .icon {
  align-items: center;
  display: flex;
  color: #000000;
  font-size: 1.2em;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 40px;
  right: 0;
  text-align: center;
  top: 0;
}

.custom-select__custom-dropdown .custom-trigger span {
  color: #333333 !important;
}

.custom-select__custom-dropdown .custom-list {
  max-height: 350px;
  opacity: 0;
  overflow: auto;
  position: absolute;
  scrollbar-color: #333333 #d2ab71;
  scrollbar-width: thin;
  scrollbar-face-color: #333333;
  scrollbar-track-color: #d2ab71;
  transform: scale(0.5);
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 999;
  border: 1px solid #000000;
  background: #d2ab71;
  border-radius: 0;
}

.custom-select__custom-dropdown .custom-list::-webkit-scrollbar {
  width: 5px;
  background: #d2ab71;
}

.custom-select__custom-dropdown .custom-list::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 5px;
}

.custom-select__custom-dropdown .custom-list li.active button {
  background: #333333;
  color: #fff;
}

.custom-select__custom-dropdown .custom-list li button {
  background: transparent;
  border: 0;
  border-radius: 0;
  display: block;
  font-size: 1em;
  padding: 10px;
  text-align: left;
  width: 100%;
}

.custom-select__custom-dropdown .custom-list li button:hover, .custom-select__custom-dropdown .custom-list li button:focus {
  background: #333333;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .custom-select__label {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .custom-select__custom-dropdown {
    display: block;
  }
}

.navigation a {
  color: white;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
}

.navigation__lvl1 {
  margin: 0 25px;
  position: relative;
}

.navigation__lvl1:hover > .navigation__lvl1--link {
  color: #b99c66;
  transition: color .5s;
}

.navigation__lvl1:hover > .navigation__lvl2--container {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
}

.navigation__lvl1--container {
  display: flex;
  flex-direction: row;
}

.navigation__lvl1--link {
  white-space: nowrap;
}

.navigation__lvl2 {
  background-color: #b99c66;
}

.navigation__lvl2:hover {
  background-color: #231f20;
  transition: background-color .5s;
}

.navigation__lvl2:hover > .navigation__lvl2--link {
  color: #b99c66;
}

.navigation__lvl2--link {
  display: block;
  padding: 14px 16px;
}

.navigation__lvl2--container {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  position: absolute;
  min-width: 180px;
  padding-top: 20px;
  z-index: 999;
}

@media (max-width: 1023px) {
  .navigation {
    width: 100%;
    background-color: #231f20;
  }
  .navigation a {
    font-size: 1.375em;
  }
  .navigation .navigation__lvl2 a {
    font-size: 1.25em;
  }
  .navigation a {
    font-family: "EB Garamond", serif;
  }
  .navigation__lvl1 {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #b99c66;
    display: flex;
    flex-direction: column;
  }
  .navigation__lvl1--link {
    padding: 15px 25px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .navigation__lvl1--link.active {
    background-color: #b99c66;
    color: #ffffff !important;
  }
  .navigation__lvl1--link.has-arrow:after {
    content: '\e905';
    font-family: 'icomoon';
    font-size: 1.5em;
  }
  .navigation__lvl1--link.has-arrow.active:after {
    transform: rotate(180deg);
  }
  .navigation__lvl1--container {
    flex-direction: column;
    font-size: 1.125em;
  }
  .navigation__lvl2 {
    background-color: #231f20;
  }
  .navigation__lvl2--link {
    padding-left: 30px;
    color: #a0a0a0 !important;
    font-weight: 300;
  }
  .navigation__lvl2--container {
    opacity: 1;
    visibility: visible;
    transform: none;
    left: 0;
    width: 100%;
    padding-top: 0;
    position: relative;
    max-height: 0;
    overflow: hidden;
    z-index: 4;
    transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);
  }
  .navigation__lvl2--container.show {
    max-height: 2000px;
    transition: max-height 1s ease-in-out;
  }
}

.gallery {
  padding: 5px 0 35px;
  margin: 35px 0 30px;
}

.gallery__title {
  font-weight: 300;
  font-size: 1.75em;
  margin-bottom: 35px;
  color: #333333;
}

.gallery-list {
  margin: 0 -5px;
}

.gallery-list__item {
  padding: 0 5px;
  outline: 0;
  position: relative;
  overflow: hidden;
}

.gallery-list__item img {
  transition: transform 0.25s cubic-bezier(0.75, 0, 0, 0.75);
}

.gallery-list__item:hover img, .gallery-list__item:focus img {
  transform: scale(1.1);
}

.gallery-list__image {
  overflow: hidden;
  display: block;
}

.gallery-list .slick-track {
  margin: 0;
}

.gallery-results {
  font-size: 1.75em;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: calc(100% - 5px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  text-shadow: 0px 1px 5px #a0a0a0;
}

.gallery-results:after {
  background: rgba(0, 0, 0, 0.45);
  content: '';
  display: block;
  height: 100%;
  width: calc(100% - 5px);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: 0 auto;
}

.gallery-results.hidden {
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  .gallery-list__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    font-family: "object-fit: cover; object-position: center center;";
  }
}

.section-img-box {
  margin: 25px 0;
}

.img-box .project-container__details--title {
  margin: 0 0 35px;
}

.img-box .project-container__details--title span {
  margin-bottom: 15px;
  padding-top: 5px;
  font-weight: 300;
  font-size: 0.875em;
  line-height: 24px;
}

.img-box .project-container__image {
  margin-bottom: 10px;
}

.img-box-fullwidth .img-box__item {
  width: 100%;
  float: none;
}

@media only screen and (min-width: 768px) {
  .section-img-box {
    margin: 65px 0 45px;
  }
  .img-box {
    margin: 0 auto;
    width: 90%;
  }
  .img-box:before, .img-box:after {
    display: table;
    content: "";
  }
  .img-box:after {
    clear: both;
  }
  .img-box__item {
    width: 50%;
    float: left;
    padding: 0 40px;
  }
  .img-box .img-box .project-container__image {
    margin-bottom: 20px;
  }
}

.timeline {
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  padding: 0px;
  list-style-type: none;
}

.timeline-section {
  position: relative;
  padding: 90px 0;
}

.timeline-section:before {
  top: 125px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #b99c66;
  left: 50%;
  margin-left: -1px;
}

.timeline-box {
  position: relative;
  float: left;
  clear: left;
  width: 45%;
  margin: 1em 2.5%;
  list-style-type: none;
  display: block;
  padding: 25px;
  background: #ececec;
  z-index: 1;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
}

.timeline-box:hover:after {
  background: #b99c66;
}

.timeline-box span {
  position: absolute;
  top: -15px;
  right: 24px;
  font-size: 8em;
  font-weight: 700;
  color: #b99c66;
  opacity: 0.1;
  z-index: -1;
}

.timeline-box:nth-child(2n):after {
  left: -6.8%;
  right: auto;
}

.timeline-box:hover, .timeline-box:focus {
  transform: translate(0, -5px);
  -webkit-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
}

.timeline-box:hover, .timeline-box:focus {
  -webkit-box-shadow: 0px 4px 0px 0px #b99c66;
  -moz-box-shadow: 0px 4px 0px 0px #b99c66;
  box-shadow: 0px 4px 0px 0px #b99c66;
}

.timeline-box:first-child {
  margin-bottom: 2.5em;
}

.timeline-box:nth-of-type(1n):before {
  position: absolute;
  top: 15px;
  right: -10px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-left: 10px solid #ececec;
  border-right: 0 solid #ececec;
  border-bottom: 10px solid transparent;
  content: " ";
}

.timeline-box:nth-of-type(2n) {
  float: right;
  clear: right;
}

.timeline-box:nth-of-type(2n):before {
  right: auto;
  left: -10px;
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-right: 10px solid #ececec;
  border-left: 0 solid #ececec;
  border-bottom: 10px solid transparent;
  content: " ";
}

.timeline-title {
  color: #3c3c3c;
  font-weight: 700;
  font-size: 2em;
  float: left;
  margin: 0;
}

.timeline-details {
  clear: both;
  padding-top: 5px;
}

@media all and (max-width: 600px) {
  .timeline-box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media all and (min-width: 650px) {
  .timeline-box:after {
    display: block;
    content: ' ';
    height: 16px;
    width: 16px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -7.2%;
    top: 1.2em;
    border: 3px solid #b99c66;
  }
}

@media all and (min-width: 600px) {
  .timeline .timeline-box:nth-of-type(2n) {
    margin-top: 3em;
  }
}

.form-modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
}

.form-modal.is-visible {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-modal__modal {
  width: 40%;
  height: auto;
  overflow: hidden;
  position: relative;
  color: white;
}

.form-modal__modal--video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.form-modal__modal--title {
  font-family: "EB Garamond", serif;
  margin: 0;
  font-size: 3.75em;
  letter-spacing: .02em;
  margin-bottom: 35px;
  font-weight: 300;
  text-align: center;
  line-height: 1.1;
}

.form-modal__modal--desc {
  font-family: "EB Garamond", serif;
  margin-bottom: 30px;
  text-align: center;
}

.form-modal__modal a {
  background-color: #b99c66;
  border: none;
}

.form-modal__modal--content {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form-modal__modal--content img {
  margin-bottom: 35px;
}

.form-modal__modal--close {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border: none;
  background-color: #b99c66;
  color: white;
  height: 45px;
  width: 45px;
  cursor: pointer;
  z-index: 6;
}

.form-modal__modal--close .icon {
  font-size: 1.2em;
}

.form-modal__half--content {
  background: white;
  color: #333333;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form-modal__half--content h2 {
  font-family: "EB Garamond", serif;
  font-size: 2em;
  font-weight: 700;
  margin: 15px 0 15px;
}

.form-modal__half--content h3 {
  font-size: 1.25em;
  margin: 0 0 5px;
  color: #231f20;
  font-weight: 300;
  line-height: 32px;
}

.form-modal__half--icon {
  position: relative;
  margin: 25px 0 20px;
}

.form-modal__half--icon .icon {
  font-size: 26px;
  line-height: 26px;
  color: #b99c66;
  position: relative;
  z-index: 1;
  background: white;
  display: inline-block;
  padding: 0 10px;
}

.form-modal__half--icon:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #b99c66;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 0;
}

.form-modal__half--footer p {
  margin-bottom: 5px;
  color: #333333;
  font-weight: 500;
}

@media (max-width: 767px) {
  .form-modal__modal {
    width: 90%;
    height: auto;
  }
  .form-modal__modal--content {
    padding-left: 5%;
    padding-right: 5%;
  }
  .form-modal__modal--content img {
    max-width: 55%;
    margin-bottom: 35px;
  }
  .form-modal__modal--title {
    font-size: 2.5em;
  }
  .form-modal__half {
    display: block;
  }
  .form-modal__half--image {
    display: block;
    width: 100%;
    height: 280px;
  }
  .form-modal__half--content {
    display: block;
    width: 100%;
    padding: 25px 30px;
  }
}

.site-header {
  padding: 35px 0;
  transition: background-color 0.3s ease-out, max-height 0.5s cubic-bezier(0, 1, 0, 1);
  transition: all 500ms ease;
  max-height: 200px;
  display: flex;
}

.site-header__details-page, .site-header__project-page {
  padding: 20px 0;
}

.site-header #logo {
  display: none;
}

.site-header #logoWhite {
  display: flex;
}

.site-header.is-fixed {
  background: white !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  -webkit-box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.1);
  max-height: 90px;
}

.site-header.is-fixed .site-header__logo {
  max-width: 85px;
}

.site-header.is-fixed .site-header__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;";
}

.site-header.is-fixed #logo {
  display: none !important;
}

.site-header.is-fixed #logoWhite {
  display: flex !important;
}

.site-header__mobile {
  display: none;
}

.site-header .container {
  position: static;
  align-items: center;
  width: 100%;
}

.site-header .navigation__lvl1--link {
  color: #333333;
}

.site-header .site-header__social a {
  color: #333333;
}

.site-header__home {
  background: linear-gradient(180deg, #020818, transparent);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.site-header__home #logo {
  display: flex;
}

.site-header__home #logoWhite {
  display: none;
}

.site-header__home .navigation__lvl1--link {
  color: #ffffff;
}

.site-header__home .site-header__social a {
  color: #ffffff;
}

.site-header .container {
  display: flex;
  justify-content: space-between;
}

.site-header__hamburger--wrapper {
  display: none;
}

.site-header__logo {
  max-width: 112px;
  transition: all 500ms ease;
}

.site-header__navigation {
  display: flex;
  align-items: center;
}

.site-header__social {
  display: flex;
  align-items: center;
}

.site-header__social a {
  color: #ffffff;
  transition: color 0.5s;
  text-decoration: none;
  margin: 0 10px;
}

.site-header__social a i {
  font-size: 1.2em;
}

.site-header__social a:hover {
  color: #b99c66;
  transition: color 0.5s;
}

@media (max-width: 1023px) {
  .site-header {
    padding: 15px 0;
  }
  .site-header__mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 25px;
  }
  .site-header__mobile--social a {
    text-decoration: none;
    color: #b99c66;
    margin-right: 20px;
    font-size: 24px;
  }
  .site-header__mobile--close {
    background: transparent;
    border: none;
    color: #b99c66;
    font-size: 1.8em;
    padding-right: 0;
    cursor: pointer;
  }
  .site-header .navigation__lvl1--link {
    color: #b99c66;
  }
  .site-header__logo {
    max-width: 90px;
  }
  .site-header__hamburger {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 25px;
  }
  .site-header__hamburger--wrapper {
    display: flex;
    align-items: center;
  }
  .site-header__hamburger span {
    top: 9px;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #b99c66;
    left: 0;
    opacity: 1;
    border-radius: 2px;
  }
  .site-header__hamburger span:before {
    content: "";
    top: -8px;
    z-index: 3;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #b99c66;
    left: 0;
    transform: rotate(0deg);
    transform-origin: left center;
    transition: transform 0.3s ease-out;
  }
  .site-header__hamburger span:after {
    content: "";
    top: 8px;
    z-index: 3;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #b99c66;
    left: 0;
    transform: rotate(0deg);
    transform-origin: left center;
    transition: transform 0.3s ease-out;
  }
  .site-header__navigation {
    opacity: 0;
    visibility: hidden;
    padding-left: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    align-items: flex-start;
    flex-direction: column;
  }
  .site-header__navigation.show {
    opacity: 1;
    visibility: visible;
    height: 100vh;
    background-color: #231f20;
  }
  .site-header__social {
    display: none;
  }
  .site-header__microsite .site-header__navigation.show, .site-header__microsite .navigation {
    background-color: #152a37;
  }
}

.site-footer {
  background-color: #333333;
  color: #ffffff;
  padding: 45px 0 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.site-footer__microsite {
  background: #152a37;
}

.site-footer__microsite .back-to-top .icon {
  background: #26708e;
}

.site-footer__microsite .back-to-top:hover .icon {
  background: #b99c66;
  color: white;
}

.site-footer__microsite .site-footer__column a:hover {
  color: #b99c66;
}

.site-footer a {
  color: #ffffff;
  text-decoration: none;
}

.site-footer a:hover {
  text-decoration: underline;
}

.site-footer__column {
  margin-bottom: 20px;
  position: relative;
}

.site-footer__column--row {
  margin-bottom: 23px;
}

.site-footer__column--row ul {
  display: flex;
}

.site-footer__column--row ul li {
  padding-right: 15px;
}

.site-footer__column--social {
  display: flex;
  justify-content: flex-end;
}

.site-footer__column--social i {
  color: #ffffff;
  transition: color .35s ease-in-out;
  font-size: 1.5em;
}

.site-footer__column--social a {
  margin-right: 20px;
}

.site-footer__column--social a:hover {
  text-decoration: none;
}

.site-footer__column--social a:hover i {
  color: #b99c66;
  transition: color .35s ease-in-out;
}

.site-footer__column h2 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 300;
  font-size: 1.125em;
  margin-bottom: 12px;
}

.site-footer__column span,
.site-footer__column a {
  font-family: "Roboto", sans-serif;
  color: #a0a0a0;
  line-height: 1.375em;
  font-size: 0.875em;
  text-decoration: none;
}

.site-footer__column a {
  margin-bottom: 12px;
  display: block;
}

.site-footer__details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.site-footer__details--each {
  width: 100%;
}

.site-footer__details__each {
  width: 100%;
}

.site-footer__bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 18px;
}

.site-footer__bottom p {
  margin: 0;
  font-size: 0.75em;
  color: #a0a0a0;
}

.site-footer__logo {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 1023px) {
  .site-footer {
    font-size: 1.375em;
  }
  .site-footer__details {
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .site-footer__details--each {
    margin-right: 0;
    width: 100%;
  }
  .site-footer__column {
    margin-bottom: 25px;
  }
  .site-footer__column--social {
    justify-content: flex-start;
  }
  .site-footer__column--row {
    justify-content: space-between;
  }
  .site-footer__logo {
    position: relative;
    text-align: right;
  }
  .site-footer__microsite {
    padding: 25px 0 15px;
  }
  .site-footer__microsite .site-footer__column--row {
    margin-bottom: 0;
  }
  .site-footer__microsite .site-footer__logo a {
    width: 95px;
    margin: 0 0 0 auto;
  }
  .site-footer__microsite .back-to-top {
    bottom: 50px;
  }
}

@media (max-width: 767px) {
  .site-footer__details {
    flex-direction: column;
  }
  .site-footer__column--row {
    display: flex;
    flex-direction: row;
  }
  .site-footer__column--row ul {
    display: block;
  }
}

.about-us {
  min-height: 470px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: white;
}

.about-us__fixed {
  height: 100vh;
  background-image: url("../images/img-about--01.jpg");
  background-size: cover;
  background-position: center center;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
  display: flex;
  align-items: center;
}

.about-us__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.45;
}

.about-us__info {
  color: #ffffff;
  text-align: center;
}

.about-us__info--title {
  font-family: "EB Garamond", serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}

.about-us__info--title h1 {
  font-weight: 300;
  font-size: 1.75em;
  margin: 0;
  margin-bottom: 10px;
}

.about-us__info--title span {
  display: block;
  font-weight: 500;
  font-size: 3em;
  line-height: 1;
  margin-bottom: 30px;
}

.about-us__info--title:after {
  content: "";
  width: 38px;
  height: 2px;
  background-color: #ffffff;
  display: inline-block;
}

.about-us__info p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  line-height: 2em;
}

.about-us__info a {
  padding: 12px 32px;
}

.about-us__title {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.about-us__title:after {
  content: "";
  width: 50px;
  height: 1px;
  background-color: #000000;
  display: inline-block;
}

.about-us__title h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 2.5em;
  color: #333333;
}

.about-us__wrap {
  padding: 70px 0 90px;
}

.about-us__icons {
  color: #000000;
  text-align: center;
}

.about-us__icons p {
  font-size: 1.125em;
}

.about-us__icon {
  padding: 0 15px;
}

.about-us .two-col__container {
  display: flex;
  align-items: center;
  background: #f9f9f9;
}

.about-us .two-col-item {
  width: 60%;
}

.about-us .two-col-item:last-child {
  width: 40%;
}

.about-us .two-col-item__text {
  padding: 0 25px;
}

.about-us__content {
  color: #000000;
  text-align: center;
}

@media (max-width: 1023px) {
  .about-us {
    min-height: 380px;
  }
  .about-us__info p {
    max-width: 100%;
    padding: 0 20px;
    margin-bottom: 35px;
    font-size: 1.25em;
    line-height: 26px;
  }
  .about-us__info--title {
    margin-bottom: 15px;
  }
  .about-us__info--title span {
    font-size: 2.375em;
    line-height: 15px;
    margin-bottom: 25px;
  }
  .about-us__overlay {
    opacity: 0.6;
  }
  .about-us__wrap {
    padding: 35px 0;
  }
  .about-us__icon {
    margin-bottom: 35px;
  }
  .about-us .two-col__container {
    display: block;
    background: #f9f9f9;
  }
  .about-us .two-col-item {
    width: 100%;
  }
  .about-us .two-col-item:last-child {
    width: 100%;
  }
  .about-us .two-col-item__text {
    padding: 25px 25px 35px;
  }
  .about-us__vision .two-col__container {
    background: #f9f9f9;
  }
  .about-us__vision .about-us__title {
    margin-top: 25px;
  }
  .about-us__vision .two-col-item__text {
    padding-bottom: 25px;
  }
}

@media (min-width: 1024px) {
  .about-us__vision .two-col__container {
    display: flex;
    align-items: center;
    background: #f9f9f9;
  }
  .about-us__vision .two-col__container img {
    display: block;
  }
  .about-us__vision .two-col-item__text {
    padding: 0 15px;
  }
}

.latest-projects {
  padding: 75px 0 90px;
  background-color: #f9f9f9;
}

.latest-projects.bg-white {
  background-color: white;
}

.latest-projects__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
}

.latest-projects__header:after {
  content: '';
  width: 50px;
  height: 1px;
  background-color: #000000;
}

.latest-projects__header h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-size: 2.5em;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}

.latest-projects__header p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1.0625em;
  color: #a0a0a0;
  margin-bottom: 27px;
}

.latest-projects__slick {
  display: flex;
  flex-direction: row;
}

.latest-projects__slick .slick-arrow {
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
}

.latest-projects__slick .slick-arrow .icon {
  font-size: 3.5em;
  color: #333333;
}

.latest-projects__slick .slick-list {
  margin-left: 2%;
  margin-right: 2%;
}

.latest-projects__project {
  padding-left: 12.5px;
  padding-right: 12.5px;
  text-decoration: none;
  color: #333333;
  transition: color .5s;
  display: block;
}

.latest-projects__project:hover {
  color: #b99c66;
  transition: color .5s;
}

.latest-projects__project:hover .latest-projects__project--overlay:before {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.latest-projects__project:hover .latest-projects__project--overlay img {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.latest-projects__project--image {
  height: 284px;
  margin-bottom: 10px;
  position: relative;
}

.latest-projects__project--overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.latest-projects__project--overlay:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000;
  opacity: .47;
  transition: opacity 0.5s;
}

.latest-projects__project--overlay img {
  transition: opacity 0.5s;
}

.latest-projects__project--logo {
  z-index: 2;
}

.latest-projects__project--details {
  padding-left: 15px;
  text-align: left;
}

.latest-projects__project--details h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1.75em;
  margin-bottom: 5px;
}

.latest-projects__project--details span {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.0625em;
}

@media only screen and (min-width: 768px) {
  .latest-projects-tiles .latest-projects__project {
    display: inline-block;
    width: 33.33333%;
  }
}

@media (max-width: 1023px) {
  .latest-projects {
    padding: 45px 0 50px;
  }
  .latest-projects__project {
    margin-bottom: 35px;
  }
  .latest-projects__project--details {
    padding-left: 0;
  }
  .latest-projects__header {
    text-align: center;
    margin-bottom: 25px;
  }
  .latest-projects__header p {
    margin-bottom: 20px;
    font-size: 1.25em;
    line-height: 26px;
  }
  .latest-projects__slick .slick-arrow .icon {
    font-size: 3em;
  }
  .latest-projects__project--image {
    height: 225px;
  }
  .latest-projects__project--overlay img {
    width: 140px;
  }
  .latest-projects__project--details h1 {
    font-size: 2em;
  }
  .latest-projects__project--details span {
    font-size: 1.25em;
  }
}

.home-banner, .microsite-banner {
  color: #ffffff;
  position: relative;
}

.home-banner__details, .microsite-banner__details {
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  transform: translateY(-60%);
  margin-bottom: 85px;
  z-index: 1;
}

.home-banner__details--title, .microsite-banner__details--title {
  font-family: "EB Garamond", serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.home-banner__details--title h1, .microsite-banner__details--title h1 {
  font-size: 4.5em;
  margin: 0;
  font-weight: 300;
  display: inline-block;
}

.home-banner__details--title span, .microsite-banner__details--title span {
  font-weight: 700;
  line-height: 1;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 25px;
}

.home-banner__details--title:after, .microsite-banner__details--title:after {
  content: '';
  width: 50px;
  height: 2px;
  background-color: #ffffff;
  display: inline-block;
}

.home-banner__details--desc, .microsite-banner__details--desc {
  margin: 45px 0 50px;
}

.home-banner__details--desc p, .microsite-banner__details--desc p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  font-size: 1.25em;
}

.home-banner__each, .microsite-banner__each {
  background: black;
  height: 100vh;
  display: flex !important;
  align-items: flex-end;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.home-banner__each .container, .microsite-banner__each .container {
  width: 100%;
  padding-bottom: 45px;
}

.home-banner__each--overlay, .microsite-banner__each--overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
}

.home-banner__each--logo, .microsite-banner__each--logo {
  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 0;
  width: 150px;
}

.home-banner__each--logo img, .microsite-banner__each--logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;";
}

.home-banner .slick-dots, .microsite-banner .slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner .slick-dots li, .microsite-banner .slick-dots li {
  margin: 0 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-banner .slick-dots li button, .microsite-banner .slick-dots li button {
  background-color: transparent;
  border: 1px solid transparent;
  width: 14px;
  height: 14px;
  position: relative;
  color: transparent;
}

.home-banner .slick-dots li button:before, .microsite-banner .slick-dots li button:before {
  content: "•";
  font-size: 4em;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}

.home-banner .slick-dots li.slick-active button:before, .microsite-banner .slick-dots li.slick-active button:before {
  color: #b99c66;
}

.microsite-banner {
  background: black;
}

.microsite-banner__each {
  color: white;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: color 800ms ease;
}

.microsite-banner__each--overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: initial;
  bottom: 0;
  width: 100%;
  background: black;
  background-repeat: repeat-x;
  background: -webkit-linear-gradient(left, transparent, black);
  /* For Safari */
  background: -o-linear-gradient(right, transparent, black);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, transparent, black);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, transparent, black);
  /* Standard syntax */
}

.microsite-banner__each:hover {
  color: #d2ab71;
}

.microsite-banner__details {
  width: 32%;
  left: initial;
  right: 10%;
  text-align: right;
}

.microsite-banner__details--title h1 {
  font-size: 2.625em;
}

.microsite-banner__details--title:after {
  display: none;
}

.microsite-banner__details--desc {
  margin: 0 0 30px;
}

.microsite-banner__details--desc p {
  font-size: 1.125em;
  line-height: 30px;
}

.microsite-banner .slick-dots {
  position: absolute;
  right: 30px;
  top: 50%;
  bottom: initial;
  left: initial;
  display: block;
}

.microsite-banner .slick-dots li {
  display: block;
  margin: 0;
}

.microsite-banner .slick-dots li button {
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.microsite-banner .slick-dots li button:before {
  content: '';
  width: 14px;
  height: 14px;
  background: white;
  display: inline-block;
  border-radius: 30px;
}

.microsite-banner .slick-dots li.slick-active button:before {
  background: #d2ab71;
}

.microsite-banner .slick-vertical .slick-slide {
  border: 0;
}

.microsite-banner .slick-slide {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.microsite-banner .slick-active {
  opacity: 1 !important;
  transition: opacity 0.8s ease-in;
}

.microsite-banner .slick-cloned {
  opacity: 0.3;
}

@media (max-width: 1023px) {
  .home-banner__details, .microsite-banner__details {
    text-align: center;
  }
  .home-banner__details .container, .microsite-banner__details .container {
    padding: 0 20px;
  }
  .home-banner__details--desc, .microsite-banner__details--desc {
    margin: 15px 0 25px;
  }
  .home-banner__details--title h1, .microsite-banner__details--title h1 {
    font-size: 3.25em;
  }
  .home-banner__details--title, .microsite-banner__details--title {
    align-items: center;
  }
  .home-banner__each--logo, .microsite-banner__each--logo {
    position: relative;
    z-index: initial;
    right: initial;
    bottom: initial;
    width: 100px;
    margin: 0 auto 10px;
  }
  .microsite-banner__details {
    top: initial;
    bottom: 0;
    transform: none;
    margin-bottom: 50px;
    width: 100%;
    right: 0;
  }
  .microsite-banner__details--title:after {
    display: block;
  }
  .microsite-banner__each--overlay {
    position: absolute;
    top: initial;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    background-repeat: repeat-x;
    background: -ms-linear-gradient(bottom, black 0%, transparent 100%);
    /* IE10 Consumer Preview */
    background: -moz-linear-gradient(bottom, black 0%, transparent 100%);
    /* Opera */
    background: -o-linear-gradient(bottom, black 0%, transparent 100%);
    background: -webkit-gradient(linear, left bottom, left bottom, color-stop(0, black), color-stop(1, transparent));
    /* Webkit (Safari/Chrome 10) */
    background: -webkit-linear-gradient(top, black 0%, transparent 100%);
    /* Webkit (Chrome 11+) */
    background: linear-gradient(to top, black 0%, transparent 100%);
    /* W3C Markup, IE10 Release Preview */
  }
  .microsite-banner .slick-dots {
    right: 0px;
  }
}

@media (max-width: 767px) {
  .home-banner, .microsite-banner {
    height: 70vh;
  }
  .home-banner__each, .microsite-banner__each {
    height: 70vh;
  }
}

.latest-news {
  padding: 70px 0 90px;
  background-color: white;
}

.latest-news__header {
  text-align: center;
  margin-bottom: 45px;
}

.latest-news__header:after {
  content: "";
  width: 50px;
  height: 1px;
  background-color: #000000;
  display: inline-block;
}

.latest-news__header h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 2.5em;
  color: #333333;
  margin-bottom: 23px;
}

.latest-news__header p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #a0a0a0;
  max-width: 40%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.0625em;
  line-height: 1.5em;
}

.latest-news ul {
  display: flex;
  flex-direction: row;
}

.latest-news ul li {
  flex-wrap: wrap;
  flex: 1;
}

.latest-news__each {
  text-decoration: none;
  color: #333333;
  display: flex;
}

.latest-news__each:hover .latest-news__each--content {
  background-color: #b99c66;
  transition: background-color 0.5s;
}

.latest-news__each:hover .latest-news__each--title:after {
  background-color: #333333;
  opacity: .5;
}

.latest-news__each:hover .latest-news__each--title span {
  color: #333333;
  opacity: 0.5;
}

.latest-news__each--inner {
  padding-left: 10px;
  padding-right: 10px;
}

.latest-news__each--inner p {
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  margin-bottom: 36px;
}

.latest-news__each--image {
  display: flex;
}

.latest-news__each--title {
  display: flex;
  flex-direction: column;
}

.latest-news__each--title:after {
  content: "";
  height: 2px;
  width: 43px;
  background-color: #b99c66;
  display: inline-block;
}

.latest-news__each--title h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  font-size: 1.5em;
  color: #333333;
  margin-bottom: 10px;
}

.latest-news__each--title span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #a0a0a0;
  font-size: 0.875em;
  margin-bottom: 27px;
}

.latest-news__each--content {
  padding: 18px;
  background-color: transparent;
  transition: background-color 0.5s;
}

@media (max-width: 1023px) {
  .latest-news {
    padding: 35px 0;
  }
  .latest-news .slick-arrow {
    border: none;
    background-color: transparent;
  }
  .latest-news .slick-arrow .icon {
    font-size: 3em;
  }
  .latest-news__header p {
    max-width: none;
    font-size: 1.25em;
    line-height: 26px;
  }
  .latest-news__header h1 {
    margin-bottom: 10px;
  }
  .latest-news__each .latest-news__each--content {
    background-color: #b99c66;
    transition: background-color 0.5s;
  }
  .latest-news__each .latest-news__each--title:after {
    background-color: #333333;
    opacity: .5;
  }
  .latest-news__each .latest-news__each--title span {
    color: #333333;
    opacity: 0.5;
  }
}

@media (max-width: 767px) {
  .latest-news__each--title h1 {
    font-size: 1.6875em;
  }
  .latest-news__each--title span {
    font-size: 1.0625em;
  }
  .latest-news__each--inner {
    padding: 0;
  }
  .latest-news__each--inner p {
    font-size: 1.125em;
  }
  .latest-news__each--image {
    height: 185px;
  }
}

.standard-banner {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.standard-banner__container {
  width: 100%;
}

.standard-banner__contact-us .standard-banner__slick--each {
  min-height: 40vh;
}

.standard-banner.has-slick {
  background-color: transparent;
}

.standard-banner .breadcrumbs-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  top: 27px;
  width: 100%;
}

.standard-banner * {
  color: #333333;
}

.standard-banner.has-background {
  height: 465px;
  padding: 0;
}

.standard-banner.has-background * {
  color: #ffffff;
}

.standard-banner.has-background .standard-banner__slick--each {
  min-height: auto;
  background-position: center;
}

.standard-banner.has-background .standard-banner__slick--each:after {
  background: rgba(0, 0, 0, 0.05);
}

.standard-banner.has-slick .breadcrumbs a {
  color: #ffffff;
}

.standard-banner.has-slick .breadcrumbs a:hover:after, .standard-banner.has-slick .breadcrumbs a:focus:after {
  color: #ffffff;
}

.standard-banner.has-slick * {
  color: #ffffff;
}

.standard-banner__slick {
  height: 100%;
  width: 100%;
  position: relative;
}

.standard-banner__slick--desc {
  font-size: 1.125em;
}

.standard-banner__slick--each {
  height: 100%;
  min-height: 80vh;
  display: flex !important;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .standard-banner__slick--each {
    min-height: auto;
  }
}

.standard-banner__slick--each:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.02);
  z-index: 1;
  width: 100%;
  height: 100%;
}

.standard-banner__slick .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;
}

.standard-banner__slick .slick-arrow .icon {
  font-size: 4em;
}

.standard-banner__slick .slick-prev {
  left: 40px;
}

.standard-banner__slick .slick-next {
  right: 40px;
}

.standard-banner__slick .slick-list,
.standard-banner__slick .slick-track {
  height: 100%;
}

.standard-banner__slick .slick-slide {
  display: flex;
}

.standard-banner__slick .slick-slide > div {
  width: 100%;
}

.standard-banner__slick .slick-dots {
  display: flex;
  justify-content: center;
  margin-top: -5%;
}

.standard-banner__slick .slick-dots li.slick-active button:before {
  color: white;
}

.standard-banner__slick .slick-dots li button {
  position: relative;
  color: transparent;
  background: none;
  border: none;
}

.standard-banner__slick .slick-dots li button:before {
  content: "•";
  font-size: 3em;
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
  cursor: pointer;
  display: flex;
}

.standard-banner__slick--container {
  width: 100%;
  z-index: 2;
}

.standard-banner__slick--container h1 {
  font-size: 2.1875em;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  margin: 0;
  margin-bottom: 34px;
}

.standard-banner__slick--container h1 span {
  font-weight: 700;
  font-size: 2.1875em;
  font-family: "EB Garamond", serif;
}

.standard-banner__title {
  font-family: "EB Garamond", serif;
  font-size: 3.75em;
  font-weight: 600;
  margin: 0;
  margin: 60px 0 25px;
}

@media only screen and (min-width: 1024px) {
  .standard-banner__slick .slick-prev {
    left: 20px;
  }
  .standard-banner__slick .slick-next {
    right: 20px;
  }
}

@media only screen and (max-width: 1279px) {
  .standard-banner__slick--container {
    padding: 0 80px;
  }
}

@media (max-width: 1023px) {
  .standard-banner__slick--container {
    padding-left: 55px;
    padding-right: 55px;
  }
  .standard-banner__slick--container h1 span {
    font-size: 1.375em;
  }
  .standard-banner__slick--container h1 {
    margin-bottom: 20px;
  }
  .standard-banner__slick--desc {
    font-size: 1.375em;
    margin: 0;
  }
  .standard-banner__slick .slick-arrow {
    font-size: .75em;
  }
  .standard-banner__slick .slick-prev {
    left: 5px;
  }
  .standard-banner__slick .slick-next {
    right: 5px;
  }
  .standard-banner__title {
    font-size: 2.875em;
    margin: 35px 0 0;
  }
}

@media (max-width: 767px) {
  .standard-banner {
    height: 135px;
  }
  .standard-banner.has-slick {
    height: 320px;
  }
  .standard-banner__slick {
    height: 100%;
  }
  .standard-banner__slick .slick-arrow {
    font-size: .5em;
    display: none !important;
  }
  .standard-banner__slick .slick-dots {
    margin-top: 0%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
  }
  .standard-banner__slick--container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .standard-banner.has-background {
    height: 400px;
  }
}

.banner-with-overlay {
  position: relative;
}

.banner-with-overlay .standard-banner__slick--each::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.banner-with-overlay .standard-banner__slick {
  z-index: 1;
}

.registration-page {
  background-color: #d2ab71;
  padding-top: 22px;
}

.registration-page .breadcrumbs a {
  color: #333333;
}

.registration-page__title {
  font-size: 3em;
  font-weight: 700;
  color: #333333;
  font-family: "EB Garamond", serif;
}

.registration-page__details {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  color: #333333;
}

.registration-page__details--title {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 1.625em;
}

.registration-page__details--subtitle {
  margin: 0;
  font-size: 1em;
  font-weight: 300;
}

.registration-page__details--subtitle span {
  color: red;
}

.registration-page__form {
  color: #333333;
}

.registration-page__form input:not([type="checkbox"]) {
  background: transparent;
  color: #333333;
  min-height: 49px;
  font-size: 1em;
  padding-left: 17px;
  border: none;
  border-bottom: 1px solid #333333;
  min-height: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  width: 100%;
}

.registration-page__form input:not([type="checkbox"])::placeholder {
  color: #333333;
}

.registration-page__form--thirds {
  justify-content: space-between;
  margin: 0 -20px 30px;
}

.registration-page__form--thirds:before, .registration-page__form--thirds:after {
  display: table;
  content: "";
}

.registration-page__form--thirds:after {
  clear: both;
}

.registration-page__form--thirds .registration-page__form--input {
  display: inline-block;
  float: left;
  width: calc(100% / 3);
  padding: 0 20px;
}

.registration-page__form--thirds .registration-page__form--input > label {
  font-weight: 600;
  margin-bottom: 10px;
}

.registration-page__form--full {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}

.registration-page__form--full input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin: 0;
}

.registration-page__form--full .registration-page__form--input {
  align-items: normal;
  width: 100%;
}

.registration-page__form--full .registration-page__form--input > label {
  font-weight: 600;
  margin-bottom: 10px;
}

.registration-page__form--full > label {
  font-size: 1em;
  margin-left: 8px;
}

.registration-page__form--input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
}

.registration-page__form--checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.registration-page__form--checkbox input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin: 0;
}

.registration-page__form--checkbox.is-column {
  flex-direction: column;
}

.registration-page__form--checkbox.is-column > label {
  margin-bottom: 18px;
}

.registration-page__form--checkbox.is-column-3 .registration-page__form--input {
  width: 33.33333%;
  margin-right: 0;
}

.registration-page__form--checkbox .registration-page__form--input {
  flex-direction: row;
  margin-right: 40px;
}

.registration-page__form--checkbox .registration-page__form--input label {
  margin-left: 8px;
  display: inline-block;
}

.registration-page__form--checkbox > label {
  min-width: 150px;
  font-weight: 600;
}

.registration-page__form--checkbox input:not([type="checkbox"]) {
  width: auto;
}

.registration-page__form--checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.registration-page__form--checkboxes .registration-page__form--input {
  margin-bottom: 15px;
  margin-right: 25px;
}

.registration-page__form #registrationSubmit {
  background-color: #333333;
  border: transparent;
  padding: 15px 25px 15px 40px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 160px;
  font-size: 1em;
  justify-content: center;
}

.registration-page__form #registrationSubmit:hover i {
  transform: translate(7px);
  transition: transform 0.5s;
}

.registration-page__form #registrationSubmit span {
  color: white;
}

.registration-page__form #registrationSubmit i {
  color: #b99c66;
  padding-left: 15px;
  font-size: 1.2em;
  transform: translateX(0);
  transition: transform 0.5s;
}

.registration-page hr {
  border-top: 1px solid #333333;
  outline: 0;
  border-bottom: 0;
  margin: 0 0 25px;
}

@media (max-width: 767px) {
  .registration-page__details {
    margin-bottom: 30px;
  }
  .registration-page__form {
    padding-bottom: 45px;
  }
  .registration-page__form #home {
    width: 100%;
  }
  .registration-page__form #hp {
    width: 100%;
  }
  .registration-page__form--input {
    flex-basis: 100% !important;
    font-size: 1.125em;
  }
  .registration-page__form input:not([type="checkbox"]) {
    margin-bottom: 0;
  }
  .registration-page__form--full {
    padding-bottom: 0;
    margin-bottom: 25px;
  }
  .registration-page__form--full > label {
    font-size: 1.125em;
  }
  .registration-page__form--full .registration-page__form--input > label {
    margin-bottom: 0;
  }
  .registration-page__form--thirds {
    margin: 0 -10px 0;
  }
  .registration-page__form--thirds .registration-page__form--input {
    padding: 0 10px;
    width: 100%;
    margin-bottom: 25px;
  }
  .registration-page__form--checkbox {
    display: flex;
    flex-wrap: wrap;
  }
  .registration-page__form--checkbox label {
    flex-basis: 100%;
  }
  .registration-page__form--checkbox > label {
    font-size: 1.125em;
    margin-bottom: 18px;
  }
  .registration-page__form--checkbox .registration-page__form--input {
    flex-basis: 50% !important;
    margin-right: 0;
  }
  .registration-page__form #registrationSubmit {
    margin-top: 30px;
    font-size: 1.25em;
  }
}

.project-container {
  margin-bottom: 90px;
}

.project-container:first-child {
  background-color: #a0a0a0;
}

.project-container__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-container__wrapper.is-reverse {
  flex-direction: row-reverse;
}

.project-container__image {
  flex-basis: 53%;
  overflow: hidden;
}

.project-container__details {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-container__details--title {
  font-size: 1.125em;
  display: flex;
  flex-direction: column;
  color: #333333;
  margin-bottom: 65px;
}

.project-container__details--title span {
  color: #a0a0a0;
  padding-top: 10px;
  margin-bottom: 35px;
}

.project-container__details--title:after {
  content: '';
  width: 45px;
  height: 2px;
  background-color: #b99c66;
}

.project-container__details--each {
  margin-bottom: 5px;
}

.project-container__details--each label {
  color: #333333;
}

.project-container__details--each span {
  color: #a0a0a0;
}

@media (max-width: 767px) {
  .project-container {
    margin-bottom: 70px;
  }
  .project-container__wrapper {
    flex-direction: column;
  }
  .project-container__wrapper.is-reverse {
    flex-direction: column;
  }
  .project-container__image {
    margin-bottom: 40px;
  }
  .project-container__details--title {
    font-size: 1.5em;
  }
  .project-container__details--title span {
    font-size: 1em;
  }
}

.project-about {
  padding-top: 74px;
  padding-bottom: 100px;
  background: #f9f9f9;
}

.project-about > .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-about__display {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 45px;
  color: #333333;
}

.project-about__display--top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-about__display--text {
  margin: 0;
}

.project-about__display--title {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-size: 2.25em;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.project-about__display--title:after {
  content: '';
  width: 60%;
  height: 1px;
  background-color: #333333;
  margin-top: 25px;
}

.project-about__more--count {
  margin-left: 22px;
  font-size: 1.875em;
  font-family: "EB Garamond", serif;
}

.project-about__slick {
  width: 55%;
}

.project-about__slick .slick-list {
  padding: 0 35% 0 0 !important;
}

.project-about__image {
  width: 340px;
  height: 360px;
}

@media (max-width: 1023px) {
  .project-about > .container {
    flex-direction: column;
  }
  .project-about__each {
    overflow: hidden;
  }
  .project-about__more {
    margin-top: 30px;
  }
  .project-about__display {
    width: 100%;
    margin-bottom: 15px;
  }
  .project-about__slick {
    width: 100%;
    /* the slides */
    /* the parent */
  }
  .project-about__slick .slick-list {
    padding: 0 !important;
  }
  .project-about__slick .slick-slide {
    margin: 0 10px;
  }
  .project-about__slick .slick-list {
    margin: 0 -10px;
  }
}

@media (max-width: 767px) {
  .project-about {
    padding-top: 36px;
  }
}

@media (max-width: 550px) {
  .project-about__slick .slick-list {
    padding: 0 10% 0 0 !important;
  }
}

.related-projects {
  background: #ececec;
  padding-top: 45px;
  padding-bottom: 65px;
}

.related-projects__header {
  font-weight: 300;
  font-size: 1.75em;
  color: #333333;
  margin: 0;
  margin-bottom: 35px;
}

.related-projects__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.related-projects__container .related-card {
  padding: 0 20px;
}

@media (max-width: 767px) {
  .related-projects__container {
    flex-direction: column;
    margin: 0;
  }
  .related-projects__container .related-card {
    padding: 0;
  }
  .related-projects__header {
    margin-bottom: 15px;
    font-weight: 700;
  }
}

.featured-projects .slick-arrow {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 52%;
  width: 65px;
  height: 65px;
  border: 1px solid #a0a0a0;
  background-color: #333333;
  cursor: pointer;
}

.featured-projects .slick-arrow.slick-prev {
  right: 64px;
}

.featured-projects .slick-arrow .icon {
  color: white;
  font-size: 2.2em;
}

.featured-projects__slick--each {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.featured-projects__slick--each > .container {
  display: flex;
  flex-wrap: wrap;
}

.featured-projects__slick--img {
  width: 100%;
  position: relative;
  height: 0;
  padding-top: 52%;
}

.featured-projects__slick--img img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.featured-projects__slick--details {
  padding-top: 35px;
  padding-bottom: 45px;
  flex-basis: 65%;
}

.featured-projects__slick--title {
  margin: 0;
  font-size: 2.125em;
  color: #333333;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.featured-projects__slick--title span {
  font-size: 18px;
  color: #a0a0a0;
  font-weight: 300;
  margin-top: 5px;
}

.featured-projects__slick--title:after {
  content: '';
  width: 45px;
  height: 2px;
  background-color: #b99c66;
  margin-top: 23px;
}

.featured-projects__slick--info li {
  color: #a0a0a0;
  font-size: 1.125em;
  margin-bottom: 5px;
}

.featured-projects__slick--info li label {
  color: #333333;
}

@media (max-width: 767px) {
  .featured-projects__slick--details {
    flex-basis: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .featured-projects__slick--title {
    font-size: 2em;
  }
  .featured-projects__slick--title span {
    font-size: 16px;
  }
  .featured-projects .slick-arrow.slick-prev {
    right: 49px;
  }
  .featured-projects .slick-arrow {
    width: 50px;
    height: 50px;
  }
}

.happenings {
  padding: 40px 0;
}

.happenings__news {
  background: #f9f9f9;
}

.happenings__news .happenings__each--content {
  background-color: #fff;
}

.happenings__header {
  text-align: center;
  margin-bottom: 45px;
}

.happenings__header:after {
  content: "";
  width: 50px;
  height: 1px;
  background-color: #000000;
  display: inline-block;
}

.happenings__header h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 2.5em;
  color: #333333;
  margin-bottom: 23px;
}

.happenings__header p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #a0a0a0;
  max-width: 40%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.0625em;
  line-height: 1.5em;
}

.happenings ul {
  margin: 0 -10px;
}

.happenings ul:before, .happenings ul:after {
  display: table;
  content: "";
}

.happenings ul:after {
  clear: both;
}

.happenings ul li {
  width: 25%;
  float: left;
}

.happenings ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;";
  display: block;
}

.happenings__each {
  text-decoration: none;
  color: #333333;
  display: flex;
}

.happenings__each:hover .happenings__each--content {
  background-color: #d2ab71;
  transition: background-color 0.5s;
}

.happenings__each:hover .happenings__each--title:after {
  background-color: #333333;
  opacity: .5;
}

.happenings__each:hover .happenings__each--title span {
  color: #333333;
  opacity: 0.5;
}

.happenings__each--inner {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  width: 100%;
}

.happenings__each--inner p {
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  margin-bottom: 36px;
}

.happenings__each--image {
  width: 100%;
}

.happenings__each--title {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.happenings__each--title:after {
  content: "";
  height: 2px;
  width: 43px;
  background-color: #d2ab71;
  display: inline-block;
}

.happenings__each--title h1 {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  font-size: 1.5em;
  color: #333333;
  margin-bottom: 10px;
}

.happenings__each--title span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #a0a0a0;
  font-size: 0.875em;
  margin-bottom: 27px;
}

.happenings__each--content {
  padding: 18px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  transition: background-color 0.5s;
}

.happenings__details--banner {
  margin-top: 35px;
}

.happenings__details--banner img {
  width: 100%;
}

.happenings__details--content {
  margin: 2em 40px 4em;
}

.happenings__details--content h1 {
  margin: 0;
  font-size: 2.25em;
  color: #333333;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.happenings__details--content h1:after {
  content: '';
  width: 45px;
  height: 2px;
  background-color: #d2ab71;
  margin-top: 23px;
}

.happenings__details--content--info li {
  padding-bottom: 10px;
  display: table;
}

.happenings__details--content--info li label {
  color: #333333;
  font-size: 1.125em;
  font-weight: 600;
  display: table-cell;
  padding-right: 10px;
}

.happenings__details--content--info li span {
  color: #a0a0a0;
  font-size: 1.125em;
}

.happenings__details--footer {
  background: #ececec;
  padding: 2em 0 4em;
}

.happenings__details--footer h2 {
  font-weight: 300;
  font-size: 1.75em;
  margin-bottom: 35px;
  color: #333333;
}

@media (max-width: 1023px) {
  .happenings .slick-arrow {
    border: none;
    background-color: transparent;
  }
  .happenings .slick-arrow .icon {
    font-size: 4em;
  }
  .happenings__header p {
    max-width: none;
  }
  .happenings__details--banner {
    margin-top: 25px;
  }
  .happenings__details--footer iframe {
    height: 320px !important;
  }
  .happenings__details--content {
    margin: 2em 20px 4em;
  }
  .happenings ul li {
    width: 50%;
  }
  .happenings__each {
    margin-bottom: 35px;
  }
  .happenings__each--title h1 {
    font-size: 1.75em;
  }
  .happenings__each--title span {
    font-size: 1.25em;
    font-weight: 600;
  }
  .happenings__each .happenings__each--content {
    padding: 15px 20px;
  }
}

@media (max-width: 767px) {
  .happenings {
    padding: 30px 0;
  }
  .happenings__each--content {
    padding: 10px;
  }
  .happenings__each .happenings__each--content {
    background-color: #d2ab71;
    transition: background-color 0.5s;
  }
  .happenings__each .happenings__each--title:after {
    background-color: #333333;
    opacity: .5;
  }
  .happenings__each .happenings__each--title span {
    color: #333333;
    opacity: 0.5;
  }
  .happenings ul li {
    width: 100%;
    float: none;
  }
}

.home-modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
}

.home-modal.is-visible {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-modal.home-modal__carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-modal.home-modal__carousel-container .home-modal__modal {
  max-width: 100%;
  position: relative;
}

.home-modal__modal {
  width: 80%;
  height: 75%;
  overflow: hidden;
  position: relative;
  color: white;
}

.home-modal__modal--video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.home-modal__modal--title {
  font-family: "EB Garamond", serif;
  margin: 0;
  font-size: 3.75em;
  letter-spacing: .02em;
  margin-bottom: 35px;
  font-weight: 300;
  text-align: center;
  line-height: 1.1;
}

.home-modal__modal--desc {
  font-family: "EB Garamond", serif;
  margin-bottom: 30px;
  text-align: center;
}

.home-modal__modal a {
  background-color: #b99c66;
  border: none;
}

.home-modal__modal--content {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-modal__modal--content img {
  margin-bottom: 35px;
}

.home-modal__modal--close {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border: none;
  background-color: #b99c66;
  color: white;
  height: 45px;
  width: 45px;
  cursor: pointer;
  z-index: 6;
}

.home-modal__modal--close .icon {
  font-size: 1.2em;
}

.home-modal__modal--image {
  overflow: hidden;
  position: absolute;
  height: 100%;
}

.home-modal__modal--overlay {
  background-color: rgba(0, 0, 0, 0.55);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.home-modal__half {
  display: flex;
}

.home-modal__half--image {
  width: 60%;
  display: block;
  position: relative;
}

.home-modal__half--image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;";
}

.home-modal__half--content {
  width: 40%;
  background: white;
  color: #333333;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.home-modal__half--content h2 {
  font-family: "EB Garamond", serif;
  font-size: 1.875em;
  font-weight: 700;
  margin: 25px 0 15px;
}

.home-modal__half--content h3 {
  font-size: 1.25em;
  margin: 0 0 5px;
  color: #a0a0a0;
  font-weight: 500;
}

.home-modal__half--content p {
  color: #a0a0a0;
  margin: 0px;
  text-transform: uppercase;
}

.home-modal__half--content a {
  background: transparent;
  font-weight: 900;
  color: #b99c66;
  text-decoration: none;
  font-size: 1.75em;
  font-family: "EB Garamond", serif;
}

.home-modal__half--icon {
  position: relative;
  margin: 25px 0 20px;
}

.home-modal__half--icon .icon {
  font-size: 26px;
  line-height: 26px;
  color: #b99c66;
  position: relative;
  z-index: 1;
  background: white;
  display: inline-block;
  padding: 0 10px;
}

.home-modal__half--icon:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #b99c66;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 0;
}

.home-modal__half--footer p {
  margin-bottom: 5px;
  color: #333333;
  font-weight: 500;
}

@media (max-width: 767px) {
  .home-modal__modal {
    width: 90%;
    height: auto;
  }
  .home-modal__modal--content {
    padding-left: 5%;
    padding-right: 5%;
  }
  .home-modal__modal--content img {
    max-width: 55%;
    margin-bottom: 35px;
  }
  .home-modal__modal--title {
    font-size: 2.5em;
  }
  .home-modal__half {
    display: block;
  }
  .home-modal__half--image {
    display: block;
    width: 100%;
    height: 280px;
  }
  .home-modal__half--content {
    display: block;
    width: 100%;
    padding: 25px 30px;
  }
}

.home-modal-carousel-container {
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.home-modal-carousel-container.is-visible {
  display: block;
}

.home-modal-carousel-container .home-modal-carousel-main-wrapper {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-modal-carousel-container .home-modal-carousel {
  display: block;
  width: 60vw;
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .home-modal-carousel-container .home-modal-carousel {
    width: 80vw;
    height: auto;
  }
}

@media (max-width: 767px) {
  .home-modal-carousel-container .home-modal-carousel {
    width: 90vw;
    height: auto;
  }
}

.home-modal-carousel-container .home-modal-carousel--close-button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border: none;
  background-color: #b99c66;
  color: white;
  height: 45px;
  width: 45px;
  cursor: pointer;
  z-index: 6;
  transition: filter .5s;
}

.home-modal-carousel-container .home-modal-carousel--close-button .icon {
  font-size: 1.2em;
}

.home-modal-carousel-container .home-modal-carousel--close-button:hover {
  filter: brightness(1.1);
}

.home-modal-carousel-container .home-modal-carousel__slide {
  display: block;
  background-color: white;
}

.home-modal-carousel-container .home-modal-carousel__slide .home-modal-carousel__content-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .home-modal-carousel-container .home-modal-carousel__slide .home-modal-carousel__content-wrapper {
    flex-direction: column;
  }
}

.home-modal-carousel-container .home-modal-carousel__slide .home-modal-carousel__content-wrapper .hyperlink {
  background: transparent;
  font-weight: 900;
  color: #b99c66;
  text-decoration: none;
  font-size: 1.75em;
  font-family: "EB Garamond", serif;
}

.home-modal-carousel-container .home-modal-carousel__slide--image {
  width: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: lightcoral;
}

@media (max-width: 767px) {
  .home-modal-carousel-container .home-modal-carousel__slide--image {
    position: relative;
    width: 100%;
  }
}

.home-modal-carousel-container .home-modal-carousel__slide--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;";
}

@media (max-width: 767px) {
  .home-modal-carousel-container .home-modal-carousel__slide--image {
    width: 100%;
  }
}

.home-modal-carousel-container .home-modal-carousel__slide--content {
  padding: 30px;
  width: 100%;
  padding-left: calc(60% + 30px);
  padding-bottom: 60px;
  text-align: center;
}

@media (max-width: 767px) {
  .home-modal-carousel-container .home-modal-carousel__slide--content {
    padding-left: 30px;
    width: 100%;
    padding-left: 30px;
  }
}

.home-modal-carousel-container .home-modal-carousel__slide--content h2 {
  margin-bottom: 0px;
  font-size: 1.5em;
}

.home-modal-carousel-container .home-modal-carousel__slide--content h3 {
  margin: 10px;
}

.home-modal-carousel-container .home-modal-carousel__slide--logo {
  margin-bottom: 30px;
}

.home-modal-carousel-container .home-modal-carousel__slide--logo img {
  margin: 10px auto;
  max-width: 100%;
}

@media (max-width: 767px) {
  .home-modal-carousel-container .home-modal-carousel__slide--logo img {
    max-width: 100px;
  }
}

.home-modal-carousel-container .home-modal-carousel__slide--footer h2 {
  font-weight: 200;
  display: inline-block;
  padding: 10px 60px;
  position: relative;
}

.home-modal-carousel-container .home-modal-carousel__slide--footer h2:before, .home-modal-carousel-container .home-modal-carousel__slide--footer h2::after {
  content: '';
  display: block;
  width: 40px;
  height: 1px;
  background-color: #b99c66;
  position: absolute;
  top: 50%;
  left: 0;
}

.home-modal-carousel-container .home-modal-carousel__slide--footer h2:after {
  right: 0;
  left: auto;
}

.home-modal-carousel-container .slide-control {
  display: block;
  position: absolute;
  top: 50%;
  border: none;
  right: 10px;
  z-index: 99;
  color: #b99c66;
  font-size: 2.5em;
  background-color: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: filter .5s;
}

.home-modal-carousel-container .slide-control.slick-hidden {
  display: none;
}

.home-modal-carousel-container .slide-control:hover {
  filter: brightness(1.4);
}

@media (max-width: 767px) {
  .home-modal-carousel-container .slide-control {
    top: 40%;
  }
}

.home-modal-carousel-container .slide-control.arrow-left {
  left: 10px;
  right: auto;
}

.home-modal-carousel-container .slide-dots {
  display: block;
  position: absolute;
  bottom: 40px;
  border: none;
  left: 80%;
  z-index: 99;
  color: #b99c66;
  font-size: 2.5em;
  background-color: transparent;
  cursor: pointer;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .home-modal-carousel-container .slide-dots {
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    top: calc(40% + 20px);
  }
}

.home-modal-carousel-container .slide-dots .slick-dots {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-modal-carousel-container .slide-dots .slick-dots li {
  width: 10px;
  height: 10px;
  float: left;
  margin: 10px;
  border-radius: 50%;
  background-color: #b99c66;
}

.home-modal-carousel-container .slide-dots .slick-dots li:hover {
  filter: brightness(1.4);
}

.home-modal-carousel-container .slide-dots .slick-dots li.slick-active {
  filter: brightness(1.4);
}

.home-modal-carousel-container .slide-dots .slick-dots li button {
  display: none;
}

.completedproject {
  margin: 3em 0;
}

.completedproject ul:before, .completedproject ul:after {
  display: table;
  content: "";
}

.completedproject ul:after {
  clear: both;
}

.completedproject li {
  width: 33.333333%;
  float: left;
  margin-bottom: 35px;
}

.completedproject .happenings__each:hover .happenings__each--content {
  background: transparent;
}

.completedproject .happenings__each:hover .happenings__each--title span {
  color: #a0a0a0;
  opacity: 1;
}

@media (max-width: 1023px) {
  .completedproject li {
    width: 50%;
    float: left;
    margin-bottom: 5px;
  }
  .completedproject .happenings__each--title span {
    margin-bottom: 15px;
  }
  .completedproject .happenings__each--inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.career-listing {
  padding: 40px 0px;
  background-color: #f7f7f7;
}

.career-listing .listing-container {
  display: flex;
  flex-wrap: wrap;
}

.career-listing .career-listing-item {
  display: block;
  padding: 10px 20px;
  width: calc(100%/3);
  padding-bottom: 40px;
  text-align: left;
  display: block;
  position: relative;
  overflow: hidden;
}

.career-listing .career-listing-item .career-listing-item-wrapper {
  height: 100%;
  background-color: white;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.career-listing .career-listing-item .career-listing-item-wrapper:hover {
  background-color: antiquewhite;
}

.career-listing .career-listing-item .career-listing-item-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  z-index: 1;
  top: 0;
  left: 0;
  transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.career-listing .career-listing-item.showing .career-listing-item-wrapper::after {
  transform: translateY(-110%);
}

@media only screen and (min-width: 320px) {
  .career-listing .career-listing-item {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .career-listing .career-listing-item {
    width: calc(100%/2);
  }
}

@media only screen and (min-width: 1024px) {
  .career-listing .career-listing-item {
    width: calc(100%/3);
  }
}

.career-listing .career-listing-item p {
  font-size: 0.9rem;
}

.career-listing .career-listing-item p.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 25px;
}

.career-listing .career-listing-item .description {
  display: block;
  flex: 1;
}

.career-listing .career-listing-item .button {
  padding: 7px 20px;
  text-align: center;
  min-width: 150px;
}

.career-detail {
  padding-top: 30px;
}

.career-detail .career-detail-container {
  align-items: stretch;
}

@media only screen and (min-width: 320px) {
  .career-detail .career-detail-container {
    display: block;
  }
  .career-detail .career-detail-container .career-title {
    width: 100%;
  }
  .career-detail .career-detail-container .career-info {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .career-detail .career-detail-container {
    display: flex;
  }
  .career-detail .career-detail-container .career-title {
    width: calc((3/12)* 100%);
  }
  .career-detail .career-detail-container .career-info {
    width: calc((9/12)* 100%);
  }
}

@media only screen and (min-width: 1024px) {
  .career-detail .career-detail-container {
    display: flex;
  }
  .career-detail .career-detail-container .career-title {
    width: calc((3/12)* 100%);
  }
  .career-detail .career-detail-container .career-info {
    width: calc((9/12)* 100%);
  }
}

.career-detail .career-detail-container h1 {
  font-size: 1.8rem;
}

.career-detail .career-detail-container .career-info-item {
  padding: 10px;
}

.career-detail .career-detail-container .career-info-item h2 {
  font-size: 1.3rem;
}

.career-detail .career-detail-container .career-info-item ul {
  padding-left: 40px;
  list-style-type: disc;
}

.career-detail .career-detail-container .career-info-item ul li {
  padding-left: 10px;
  padding-bottom: 5px;
  line-height: 1.5;
}

.career-detail .career-detail-container .career-info-item .additional-item {
  padding-bottom: 10px;
}

.career-detail .career-detail-container .career-info-item .additional-item p {
  margin: 5px 0px;
}

.career-detail .cta-container {
  padding-bottom: 10px;
}

.career-detail .cta-container a {
  width: 100%;
  max-width: 250px;
}

.location-map {
  padding-top: 70px;
  padding-bottom: 100px;
}

.location-map__map {
  margin-bottom: 35px;
}

.location-map__header {
  font-weight: 700;
  font-size: 1.75em;
  margin: 0;
  margin-bottom: 20px;
}

.location-map__address {
  display: flex;
  align-items: center;
  color: #333333;
}

.location-map__address .icon {
  font-size: 2.55em;
  margin-right: 23px;
}

.location-map__address--text h2 {
  margin: 0;
  font-weight: 700;
  font-size: 1.25em;
  margin-bottom: 9px;
}

.location-map__address--text span {
  font-weight: 300;
  font-size: 1.25em;
}

.location-map__directions {
  display: flex;
  padding-top: 45px;
}

.location-map__directions--each {
  text-decoration: none;
  color: #333333;
  margin-right: 70px;
}

.location-map__directions--each img {
  margin-right: 17px;
}

.location-map__directions--each:hover .icon {
  transform: translateX(7px);
  transition: transform .5s;
}

.location-map__directions--text {
  display: flex;
  align-items: center;
  font-size: 1em;
  padding-bottom: 9px;
  border-bottom: 1px solid #333333;
}

.location-map__directions--text p {
  margin: 0;
}

.location-map__directions--text .icon {
  padding-left: 15px;
  transform: translateX(0);
  transition: transform .5s;
}

.location-map #map {
  width: 100%;
  height: 500px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .location-map {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .location-map__directions {
    flex-direction: column;
    align-items: flex-start;
  }
  .location-map__directions--each {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.contact-details {
  background-color: #f9f9f9;
  padding-top: 75px;
  padding-bottom: 60px;
}

.contact-details > .container {
  display: flex;
}

.contact-details__each {
  padding-right: 107px;
  color: #333333;
}

.contact-details__each--header {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 50px;
  letter-spacing: .05em;
  white-space: nowrap;
  position: relative;
}

.contact-details__each--header:after {
  content: '';
  position: absolute;
  background-color: #b99c66;
  width: 45px;
  height: 2px;
  bottom: -20px;
  left: 0;
}

.contact-details__each--title {
  font-weight: 700;
  font-size: 1.25em;
  margin-bottom: 10px;
  display: inline-block;
}

.contact-details__each--text {
  margin: 0;
  margin-bottom: 20px;
}

.contact-details__each a {
  color: black;
  text-decoration: none;
}

.contact-details__each a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .contact-details {
    padding-top: 35px;
    padding-bottom: 10px;
  }
  .contact-details > .container {
    flex-direction: column;
  }
  .contact-details__each {
    margin-bottom: 10px;
  }
  .contact-details__each--header {
    margin-bottom: 25px;
  }
  .contact-details__each--header:after {
    bottom: -10px;
  }
}

.contact-us .standard-banner {
  padding: 0;
}

.contact-form {
  padding-top: 65px;
  padding-bottom: 100px;
  background-color: #d2ab71;
}

.contact-form__title {
  font-weight: 700;
  font-size: 1.75em;
  margin: 0;
  margin-bottom: 12px;
  color: #333333;
}

.contact-form__subtitle {
  font-weight: 300;
  font-size: 1em;
  color: #333333;
}

.contact-form__subtitle a {
  color: #333333;
}

.contact-form form {
  padding-top: 48px;
}

.contact-form__form label {
  margin-bottom: 13px;
}

.contact-form__form--container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.contact-form__form--container:last-of-type {
  margin-bottom: 0;
}

.contact-form__form--container .contact-form__form--half {
  padding-left: 20px;
}

.contact-form__form--container .contact-form__form--half:first-of-type {
  padding-right: 20px;
  padding-left: 0;
}

.contact-form__form--container button {
  background-color: #333333;
  border: transparent;
  padding: 15px 25px 15px 40px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.contact-form__form--container button:hover i {
  transform: translate(7px);
  transition: transform 0.5s;
}

.contact-form__form--container button span {
  color: white;
}

.contact-form__form--container button i {
  color: #b99c66;
  padding-left: 15px;
  font-size: 1.2em;
  transform: translateX(0);
  transition: transform 0.5s;
}

.contact-form__form--half {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-form__form--full {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-form__form--full textarea {
  padding-left: 17px;
  padding-top: 18px;
  font-size: 1em;
  min-height: 250px;
  font-family: "Roboto", sans-serif;
}

.contact-form__input {
  background: transparent;
  color: #333333;
  min-height: 49px;
  font-size: 1em;
  padding-left: 17px;
  border: 1px solid #333333;
}

.contact-form__input::placeholder {
  color: #333333;
}

.contact-form__checkbox {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.contact-form__checkbox input {
  margin: 0 15px 0 0;
}

.contact-form__checkbox label {
  width: 98%;
  font-size: 15px;
  line-height: 22px;
}

.contact-form__checkbox label a {
  color: #000000;
}

.contact-form__checkbox label a:hover {
  color: slategrey;
}

@media (max-width: 767px) {
  .contact-form {
    padding-top: 35px;
    padding-bottom: 50px;
  }
  .contact-form__form--half {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .contact-form__form--full {
    margin-bottom: 30px;
  }
  .contact-form__form--container {
    flex-direction: column;
    margin-bottom: 0;
  }
  .contact-form__form--container:last-of-type {
    align-items: flex-start;
    margin-top: 30px;
  }
  .contact-form__form--container .contact-form__form--half {
    padding-left: 0;
  }
  .contact-form__form--container .contact-form__form--half:first-of-type {
    padding-right: 0px;
  }
}

/*========================================================================
>> Common for all the pages
========================================================================== */
button:focus,
input:focus,
select:focus {
  outline: none;
}

.object-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* =======================================================================
04 Mediaqueries
========================================================================== */
@media (max-width: 1023px) {
  html {
    font-size: .875em;
  }
  body {
    font-size: .875em;
  }
}
