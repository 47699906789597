// contact-form styles
.contact-us .standard-banner {
  padding: 0;
}
.contact-form {
  padding-top: 65px;
  padding-bottom: 100px;

  background-color: #d2ab71;

  &__title {
    font-weight: $bold;

    font-size: calc-em(28px);

    margin: 0;
    margin-bottom: 12px;

    color: $dark-grey;
  }

  &__subtitle {
    font-weight: $regular;

    font-size: calc-em(16px);

    color: $dark-grey;

    a {
      color: $dark-grey;
    }
  }

  form {
    padding-top: 48px;
  }

  &__form {
    label {
      margin-bottom: 13px;
    }

    &--container {
      display: flex;
      flex-wrap: wrap;

      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .contact-form__form--half {
        padding-left: 20px;

        &:first-of-type {
          padding-right: 20px;
          padding-left: 0;
        }
      }

      button {
        @include button-design;
      }
    }

    &--half {
      flex: 1;

      display: flex;
      flex-direction: column;
    }

    &--full {
      display: flex;
      flex-direction: column;
      width: 100%;

      textarea {
        padding-left: 17px;
        padding-top: 18px;
        font-size: calc-em(16px);
        min-height: 250px;
        font-family: $roboto;
      }
    }
  }

  &__input {
    @include input-design;
    
    border: 1px solid $dark-grey;
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    // align-items: baseline;
    align-items: end;

    input {
      margin: 0 15px 0 0;
    }
    label {
      width: 98%;
      font-size: 15px;
      line-height: 22px;
      a {
        color: $black0;

        &:hover {
        color: slategrey;
        }
      }
    }
  }
}

@include mobile {
  .contact-form {
    padding-top: 35px;
    padding-bottom: 50px;
    &__form {
      &--half {
        padding-left: 0;

        margin-bottom: 30px;
      }

      &--full {
        margin-bottom: 30px;
      }

      &--container {
        flex-direction: column;

        margin-bottom: 0;

        &:last-of-type {
          align-items: flex-start;

          margin-top: 30px;
        }

        .contact-form__form--half {
          padding-left: 0;

          &:first-of-type {
            padding-right: 0px;
          }
        }
      }
    }
  }
}
