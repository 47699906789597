.career-detail {
  padding-top: 30px;
  .career-detail-container  {

    align-items: stretch;
    @media only screen and (min-width: $bpMobile) {
      display: block;
      .career-title {
        width: 100%;
      }
      .career-info {
        width: 100%;
      }
    }

    @media only screen and (min-width: $bpTablet) {
      display: flex;
      .career-title {
        width: calc((3/12)* 100%);
      }
      .career-info {
        width: calc((9/12)* 100%);
      }
    }
    
    @media only screen and (min-width: $bpDesktop) {
      display: flex;
      .career-title {
        width: calc((3/12)* 100%);
      }
      .career-info {
        width: calc((9/12)* 100%);
      }
    }
    h1 {
      font-size: 1.8rem;
    }
    
    .career-info-item {
      padding: 10px;
      h2 {
        font-size: 1.3rem;
      }
      ul {
        padding-left: 40px;
        list-style-type: disc;
        li {
          padding-left: 10px;
          padding-bottom: 5px;
         
          line-height: 1.5;
        }
      }
      .additional-item {
        padding-bottom: 10px;
        p {
          margin: 5px 0px;
        }
      }
      .description {

      }
    }
  }
  .cta-container {
    padding-bottom: 10px;
    a {
      width: 100%;
      max-width: 250px;
    }
  }
}