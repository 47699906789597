// Breakpoints
$bpMobile: 320px;
$bpLgMobile: 480px;
$bpTablet: 768px;
$bpDesktop: 1024px;
$bpLgDesktop: 1280px;

// fonts
$eb: 'EB Garamond', serif;
$roboto: 'Roboto', sans-serif;
$notoCN: 'Noto Sans SC', sans-serif;
$noto: 'Noto Sans', sans-serif;

$base-fontsize: 16px;

// font weights
$regular: 300;
$medium: 500;
$semibold: 600;
$bold: 700;
$black: 900;

$gold: #b99c66;
$gold2: #d2ab71;
$brown: #231f20;
$off-white: #f9f9f9;
$black1: #f9f9f9;
$black0: #000000;
$white: #ffffff;
$silver: #a0a0a0;
$dark-grey: #333333;

// microsite color
$lightblue: #58c0f4;
$lightblue2: #26708e;
$darkblue: #152a37;

$easeInOutMotion: cubic-bezier(0.75, 0, 0, 0.75);
