.project-about-us {

    .about-us__container h1 {
        font-family: $roboto;
        font-weight: $medium;
    }

    .about-us__container--right h2 {
        font-family: $roboto;
        font-weight: $medium;
    }

    .about-us__container > .container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

}