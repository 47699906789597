// navigation styles
.navigation {

  a {
    color: white;
    text-decoration: none;

    font-family: $roboto;
    font-weight: $medium;

    font-size: calc-em(16px);
  }

  // level 1
  &__lvl1 {
    margin: 0 25px;

    position: relative;


    &:hover {

      > .navigation__lvl1--link {
        color: $gold;

        transition: color .5s;
      }

      > .navigation__lvl2--container {
        // animation: .5s fadeInDown 1;
        opacity: 1;

        visibility: visible;

        transform: translateY(0);

        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
      }

    }

    &--container {
      display: flex;
      flex-direction: row;
    }


    &--link {
      white-space: nowrap;

    }

  }

  // level 2
  &__lvl2 {
    background-color: $gold;



    &:hover {
      background-color: $brown;

      transition: background-color .5s;

      > .navigation__lvl2--link {
        color: $gold;
      }

    }

    &--link {
      display: block;

      padding: 14px 16px;
    }

    &--container {
      opacity: 0;
      visibility: hidden;

      transform: translateY(-20px);

      transition: opacity .5s ease-in-out, transform .5s ease-in-out;

      position: absolute;

      min-width: 180px;

      padding-top: 20px;

      z-index: 999;
    }

  }

}


@include mobile-tablet {

  .navigation {
    width: 100%;

    background-color: $brown;

    a {
      font-size: calc-em(22px);


    }
    .navigation__lvl2 a {
      font-size: calc-em(20px);
    }

    a {
      font-family: $eb;
    }


    &__lvl1 {
      padding: 0;
      
      margin: 0;

      border-bottom: 1px solid $gold;
      
      
      display: flex;
      flex-direction: column;


      &--link {
        padding: 15px 25px;

        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.active {
          background-color: $gold;

          color: $white !important;
        }

        // downward icon

        &.has-arrow {

          &:after {
            content: '\e905';
            font-family: 'icomoon';
  
            font-size: 1.5em;
          }
  
          &.active {
  
            &:after {
              transform: rotate(180deg);
            }
            
          }

        }

        

      }


      &--container {
        flex-direction: column;

        font-size: calc-em(18px);
      }

    }


    &__lvl2 {
      background-color: $brown;

      &--link {
        padding-left: 30px;

        color: $silver !important;
        font-weight: $regular;

      }

      &--container {
        opacity: 1;
        visibility: visible;

        transform: none;

        left: 0;

        width: 100%;

        padding-top: 0;

        position: relative;

        max-height: 0;
        overflow: hidden;

        z-index: 4;

        transition: max-height .4s cubic-bezier(0, 1, 0, 1);


        &.show {
          max-height: 2000px;

          transition: max-height 1s ease-in-out;
        }


      }


    }


  }

}

