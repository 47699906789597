// sorter styles
// listing-sorter styles
@keyframes popup {
    0% {
        transform: scale(0);
    }

    70% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes popupClose {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(0);
    }
}

%options {
    position: relative;

    button {
        width: 100% !important;
        font-size: calc-em(16px);
        line-height: 18px;
        background-color: transparent !important;
        border: 1px solid $black0 !important;
        padding: 15px 25px 15px 17px !important;
        position: relative;
        text-align: left;
        cursor: pointer;
        
        border-radius: 0 !important;
        letter-spacing: -0.2px;

        span {
            color: $black0 !important;
        }

        i {
            display: inline-block;
            position: absolute;
            font-size: calc-em(26px) !important;
            top: 15px;
            right: 10px;
            color: $black0 !important;
        }

        &:hover {
            i {
                transform: none !important;
                transition: none !important;
            }
        }
    }

    > ul {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    }

    ul {
        @extend %list-unstyled;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 2;
        // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        transform-origin: 50% 5%;
        transform: scale(0);
        max-height: 270px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 3px;
        }

        &::-webkit-scrollbar-track-piece {
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: $black0;
        }

        &.opened {
            animation: popup 0.25s ease-out 0s 1;
            transform: scale(1);
        }

        &.closed {
            animation: popupClose 0.25s ease-out 0s 1;
            transform: scale(0) !important;
        }
    }

    li {
        display: block;

        a, button, span {
            width: 100%;
            display: block;
            font-size: calc-em(16px);
            line-height: 18px;
            background-color: $dark-grey;
            padding: 16px 20px;
            margin:0;
            color: $white;
            border: none;
            outline: 0;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                background-color: darken($white, 5%);
                color: $black;
                
            }
        }

        a, button {
            &:hover {
                
            }
        }
    }
}

.sorter {
    margin: 0 0 30px;

    > div {
        display: block;

        > * {
            width: 100%;
        }
    }

    &__label {
        font-size: calc-em(18px);
        font-weight: normal;
        margin-right: 15px;
        letter-spacing: -0.2px;
        margin-bottom: 13px;
        display: block;
    }

    &__options {
        @extend %options;
    }
}

@media only screen and (min-width: $bpTablet){
    .sorter {
        margin:0;

        > div > * {
        }

        &__label {
            font-size: calc-em(15px);
            margin-right: 23px;
        }

        &__options {
            a, button {
                width: 300px;
                font-size: calc-em(15px);
            }

            a {
                font-size: calc-em(15px);
            }

            ul {
                width: 100%;
            }
        }
    }
}
