// styling for about header
// .standard-banner__about-us h1 {
//     font-size: calc-em(48px);

//     font-family: $roboto;

//     font-weight: $regular;

//     top: 50%;
// }

.about-us {

    &__intro {
        padding: 80px 0;

        .container {
            display: flex;

            align-items: flex-start;

            img {
                margin-left: 30px;

                max-width: 230px;
            }

        }

        &--paragraphs {
            padding-left: 55px;
            
            p {
                margin-top: 0;

                font-size: calc-em(18px);

                line-height: 1.77;

                color: $dark-grey;
            }

        }

    }

    &__background {
        min-height: 500px;

        position: relative;

        img {
            position: absolute;
        }

    }

    &__container {
        padding-top: 58px;
        padding-bottom: 10px;

        &--right {

            h2 {
                margin: 0;

                font-family: $eb;

                font-weight: $semibold;
                
                font-size: calc-em(22px);

                color: $dark-grey;

                margin-bottom: 12px;
            }

            p {
                margin: 0;

                font-weight: $regular;

                font-size: calc-em(18px);

                color: $silver;

                margin-bottom: 32px;
            }

        }


        > .container {

            display: flex;

            // justify-content: flex-start;

        }

        h1 {
            margin: 0;

            font-family: $eb;

            color: $dark-grey;
            font-size: calc-em(24px);

            width: 45%;

            margin-right: 105px;

        }
    }

    &__select {
        display: flex;
        flex-direction: column;

        align-items: flex-start;

        margin-left: 45px;

        .icon {
            border: none;
            background-color: transparent;

            font-size: 2em;

            color: $dark-grey;

            cursor: pointer;

            &:hover {
                color: $gold;
            }


            &:last-of-type {
                position: relative;

                transform: rotate(180deg);
            }

        }

        a {
            color: $gold;

            padding: 7px 5px;

            background-color: transparent;

            // display: flex;
            font-size: calc-em(14.09px);

            text-decoration: none;

            border-radius: 14px;

            white-space: nowrap;

            transition: background-color .5s, color .5s;

            margin-bottom: 11px;

            &:hover,
            &.active {

                background-color: $gold;
                color: $white;

                transition: background-color .5s, color .5s;

            }

        }




    }

    &__milestones {
        padding-top: 72px;
        padding-bottom: 100px;

        background-color: $black1;


        > .container {
            display: flex;
            flex-direction: row;
        }

    }

    &__milestone {
        visibility: hidden;
        opacity: 0;
        max-height: 0;

        overflow: hidden;

        // transition: opacity .4s ease-in;
        
        &.show {
            visibility: visible;
            opacity: 1;

            max-height: 2000px;

            transition: opacity .4s ease-in;
        }

        &--image {
            height: 620px;
        }

        &--details {
            display: flex;

            padding-top: 25px;
        }

        &--left {
            border-right: 1px solid $dark-grey;

            padding-right: 34px;


            h2 {
                font-family: $eb;

                color: $dark-grey;

                margin: 0;

                font-family: $semibold;

                font-size: calc-em(32px);
            }

            p {
                margin: 0;

                color: $gold;

                font-family: $bold;

                font-size: calc-em(22px);
            }
        }

        &--right {
            padding-left: 64px;

            p {
                margin: 0;

                font-weight: $regular;

                font-size: calc-em(20px);

                color: $dark-grey;
            }
        }
    }
}


@include mobile {

    .about-us {

        &__intro {

            .container {
                flex-direction: column;

                img {
                    margin-left: 0;

                    margin-bottom: 40px;
                }

            }

            &--paragraphs {
                padding-left: 0;
            }

        
        }


        &__background {
            position: relative;

            padding-top: 56.25%;

            min-height: 0;


            img {
                top:0;
                left:0;
                bottom:0;
                right:0;
            }

        }

        &__container {

            h1 {
                margin-bottom: 40px;
            }

            > .container {
                flex-direction: column;


            }

        }


    }

}


@include mobile {

    .about-us {

        &__select {
            margin: 0;

            flex-direction: row;
            justify-content: space-between;
        }

        &__milestones {

            > .container {
                flex-direction: column-reverse;
            }

        }

        &__milestone {

            &--left {
                border-right: 0;

                padding-right: 0;
                padding-bottom: 20px;
            }

            &--right {
                padding-left: 0;
            }

            &--details {
                flex-direction: column;
            }

            &--image {
                height: auto;
            }

        }

    }

}