// home-modal styles
.home-modal-carousel-container {
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  &.is-visible {
    display: block;

  }

  .home-modal-carousel-main-wrapper {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // @include tablet {
    //   max-width: 80vw;
    // }
    // @include mobile {
    //   max-width: 90vw;
    // }
  }
  .home-modal-carousel {
    display: block;
    width: 60vw;
    position: relative;
    @include tablet {
      width: 80vw;
      height: auto;
    }
    @include mobile {
      width: 90vw;
      height: auto;
    }
    
    &--close-button {
      position: absolute;
      top: 0;
      right: 0;
  
      border-radius: 0;
  
      border: none;
  
      background-color: $gold;
  
      color: white;
  
      height: 45px;
      width: 45px;
  
      cursor: pointer;
  
      z-index: 6;
      transition: filter .5s;
      .icon {
        font-size: 1.2em;
      }
      
      
      &:hover {

        filter: brightness(1.1);

        
            
      }
  
    }
  }

  .home-modal-carousel__slide {
    display:block;
    background-color: white;
    .home-modal-carousel__content-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      @include mobile {
        flex-direction: column;
      }
      .hyperlink {
        background: transparent;
        font-weight: $black;
        color: $gold;
        text-decoration: none;
        font-size: calc-em(28px);
        font-family: $eb;
      }
    }
    &--image {
      width: 60%;
      height: 100%;
      
      position: absolute;
      top: 0;
      left: 0;
      background-color: lightcoral;
      @include mobile {
        position: relative;
        width: 100%;
      }
      img {
     
        @include objectfit;
      }
      @include mobile {
        width: 100%;
      }
    }

    &--content {
      padding: 30px;
      width:100%;
      padding-left: calc(60% + 30px);
      padding-bottom: 60px;
      @include mobile {
        padding-left: 30px;
        width: 100%;
        padding-left: 30px;
      }
      text-align: center;
      h2 {
        margin-bottom: 0px;
        font-size: 1.5em;
      }
      h3 {
        margin: 10px
      }
    }
    &--logo {
      margin-bottom: 30px;
      img {
        margin: 10px auto;
        max-width: 100%;
      }
      @include mobile {
        img {
          max-width: 100px;
        }
      }
    }
    &--footer {
      h2 {
        font-weight: 200;
        display: inline-block;
        padding: 10px 60px;
        position: relative;
        &:before, &::after {
          content: '';
          display: block;
          width: 40px;
          height: 1px;
          background-color: $gold;
          position: absolute;
          top: 50%;
          left: 0;
        }
        &:after {
          right: 0;
          left: auto;
        }
      }
    }
    
  }

 
  .slide-control {
    display: block;
    position: absolute;
    top: 50%;
    border: none;
    right: 10px;
    z-index: 99;
    color: $gold;
    font-size: 2.5em;
    background-color: transparent;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: filter .5s;
    &.slick-hidden {
      display: none;
    }
    &:hover {
      filter: brightness(1.4); 
    }
    @include mobile {
      top: 40%;
    }
    &.arrow-left {
      left: 10px;
      right: auto;
    }
  }

  .slide-dots {
    display: block;
    position: absolute;
    bottom: 40px ;
    border: none;
    left: 80%;
    z-index: 99;
    color: $gold;
    font-size: 2.5em;
    background-color: transparent;
    cursor: pointer;
    transform: translateX(-50%);
    @include mobile {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
      top: calc(40% + 20px);
    }
    .slick-dots {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      li {
        width: 10px;
        height: 10px;
        
        float: left;
        margin: 10px;
        border-radius: 50%;
        background-color: $gold;
        &:hover {
          filter: brightness(1.4); 
        }
        &.slick-active {
          filter: brightness(1.4);
        }
        button {
          display: none;
        }
      }
    }
  }

}
