// latest-news styles
.latest-news {
  padding: 70px 0 90px;

  background-color: white;

  &__header {
    text-align: center;

    margin-bottom: 45px;

    &:after {
      content: "";

      width: 50px;
      height: 1px;

      background-color: $black0;

      display: inline-block;
    }

    h1 {
      margin: 0;

      font-family: $eb;
      font-weight: $medium;

      font-size: calc-em(40px);

      color: $dark-grey;

      margin-bottom: 23px;
    }

    p {
      margin: 0;

      font-family: $roboto;
      font-weight: $regular;

      color: $silver;

      max-width: 40%;

      margin-left: auto;
      margin-right: auto;

      font-size: calc-em(17px);
      line-height: calc-em(24px);
    }
  }

  ul {
    display: flex;
    flex-direction: row;


    li {
        flex-wrap: wrap;
        flex: 1;
    }


  }

  &__each {
    text-decoration: none;

    color: $dark-grey;

    display: flex;

    &:hover {
      .latest-news__each--content {
        background-color: $gold;

        transition: background-color 0.5s;
      }

      .latest-news__each--title:after {
          background-color: $dark-grey;
          opacity: .5;
      }

      .latest-news__each--title span {
        color: $dark-grey;
        opacity: 0.5;
      }

    }

    &--inner {
      padding-left:10px;
      padding-right:10px;

      p {
        font-size: calc-em(16px);

        font-family: $roboto;
        font-weight: $regular;

        margin: 0;

        margin-bottom: 36px;
      }
    }

    &--image {
      display: flex;
    }

    &--title {
      // margin-bottom: 20px;

      display: flex;
      flex-direction: column;

      &:after {
        content: "";

        height: 2px;
        width: 43px;

        background-color: $gold;

        display: inline-block;
      }

      h1 {
        margin: 0;

        font-family: $eb;
        font-weight: $semibold;

        font-size: calc-em(24px);

        color: $dark-grey;

        margin-bottom: 10px;
      }

      span {
        font-family: $roboto;
        font-weight: $medium;

        color: $silver;

        font-size: calc-em(14px);

        margin-bottom: 27px;
      }
    }

    &--content {
      padding: 18px;

      background-color: transparent;

      transition: background-color 0.5s;
    }
  }
}


@include mobile-tablet {


  .latest-news {
    padding: 35px 0;
    .slick-arrow {
      border: none;

      background-color: transparent;

      
      .icon {
        font-size: 3em;
      }


    }

    &__header p {
      max-width: none;
      font-size: calc-em(20px);
      line-height: 26px;
    }

    &__header h1 {
      margin-bottom: 10px;
    }

    

    &__each {
      .latest-news__each--content {
        background-color: $gold;

        transition: background-color 0.5s;
      }

      .latest-news__each--title:after {
          background-color: $dark-grey;
          opacity: .5;
      }

      .latest-news__each--title span {
        color: $dark-grey;
        opacity: 0.5;
      }
    }
  }

}


@include mobile {


  .latest-news {


    &__each {

      &--title {

        h1 {
          font-size: calc-em(27px);
        }

        span {
          font-size: calc-em(17px);
        }

        

      }

      &--inner {
        padding: 0;
        p {
          font-size: calc-em(18px);
        }
      }

      &--image {
        height: 185px;
      }
    }


  }

}
