// img-box styles
.section-img-box {
    margin: 25px 0;
}
.img-box {

    &__item {

    }

    .project-container__details--title {
        margin: 0 0 35px;
    }

    .project-container__details--title span {
        margin-bottom: 15px;
        padding-top: 5px;
    font-weight: $regular;
    font-size: calc-em(14px);
    line-height: 24px;
    }

    .project-container__image {
        margin-bottom: 10px;
    }

    &-fullwidth {
        .img-box__item {
            width: 100%;
            float:none;
        }
    }
}

@media only screen and (min-width: $bpTablet){
    .section-img-box {
        margin: 65px 0 45px;
    }
    .img-box {
        @include clearfix;
        margin: 0 auto;
        width: 90%;

        &__item {
            width: 50%;
            float: left;
            padding: 0 40px;
        }

        .img-box .project-container__image {
            margin-bottom: 20px;
        }
    }
    
}