// happenings styles
.happenings {
    padding: 40px 0;

    &__news {
      background: $black1;

      .happenings__each--content {
        background-color: #fff;
      }
    }

    &__header {
      text-align: center;
  
      margin-bottom: 45px;
  
      &:after {
        content: "";
  
        width: 50px;
        height: 1px;
  
        background-color: $black0;
  
        display: inline-block;
      }
  
      h1 {
        margin: 0;
  
        font-family: $eb;
        font-weight: $medium;
  
        font-size: calc-em(40px);
  
        color: $dark-grey;
  
        margin-bottom: 23px;
      }
  
      p {
        margin: 0;
  
        font-family: $roboto;
        font-weight: $regular;
  
        color: $silver;
  
        max-width: 40%;
  
        margin-left: auto;
        margin-right: auto;
  
        font-size: calc-em(17px);
        line-height: calc-em(24px);
      }
    }
  
    ul {
      @include clearfix;
      margin: 0 -10px;
  
      li {
          width: 25%;
          float: left;

          img {
            @include objectfit;
            display: block;
          }
      }
  
  
    }
  
    &__each {
      text-decoration: none;
  
      color: $dark-grey;
  
      display: flex;
  
      &:hover {
        .happenings__each--content {
          background-color: $gold2;
  
          transition: background-color 0.5s;
        }
  
        .happenings__each--title:after {
            background-color: $dark-grey;
            opacity: .5;
        }
  
        .happenings__each--title span {
          color: $dark-grey;
          opacity: 0.5;
        }
  
      }
  
      &--inner {
        padding-left: 10px;
        padding-right: 10px;
        display: block;
    width: 100%;
  
        p {
          font-size: calc-em(16px);
  
          font-family: $roboto;
          font-weight: $regular;
  
          margin: 0;
  
          margin-bottom: 36px;
        }
      }
  
      &--image {
        width: 100%;
      }
  
      &--title {
        margin-bottom: 0;
  
        display: flex;
        flex-direction: column;
  
        &:after {
          content: "";
  
          height: 2px;
          width: 43px;
  
          background-color: $gold2;
  
          display: inline-block;
        }
  
        h1 {
          margin: 0;
  
          font-family: $eb;
          font-weight: $semibold;
  
          font-size: calc-em(24px);
  
          color: $dark-grey;
  
          margin-bottom: 10px;
        }
  
        span {
          font-family: $roboto;
          font-weight: $medium;
  
          color: $silver;
  
          font-size: calc-em(14px);
  
          margin-bottom: 27px;
        }
      }
  
      &--content {
        padding: 18px;
        margin-bottom: 15px;
        background-color: #f9f9f9;
  
        transition: background-color 0.5s;
      }
    }

    &__details {
        &--banner {
            
            margin-top: 35px;
            img {
                width: 100%;
                
            }
        }

        &--content {
          margin: 2em 40px 4em;
            h1 {
               margin: 0;
                font-size: calc-em(36px);
                color: $dark-grey;
                display: flex;
                flex-direction: column;
                margin-bottom: 35px;

                &:after {
                    content: '';
                    width: 45px;
                    height: 2px;
                    background-color: $gold2;
                    margin-top: 23px;
                }

            }
    
            &--info {
                li {
                    padding-bottom: 10px;
                    display: table;
                    label {
                        color: $dark-grey;
                        font-size: calc-em(18px);
                        font-weight: $semibold;
                        display: table-cell;
                        padding-right: 10px;
                    }
                    span {
                        color: $silver;
                        font-size: calc-em(18px);
                    }
                }
            }
        }

        &--footer {
          background: #ececec;
          padding: 2em 0 4em;
          h2 {
            font-weight: $regular;
            font-size: calc-em(28px);
            margin-bottom: 35px;
            color: $dark-grey;
          }
        }
    }
  }
  
  
  @include mobile-tablet {
  
  
    .happenings {
  
      .slick-arrow {
        border: none;
  
        background-color: transparent;
  
        
        .icon {
          font-size: 4em;
        }
  
  
      }
  
      &__header p {
        max-width: none;
      }

      &__details {
            &--banner {
                
                margin-top: 25px;
            }

            &--footer {
              iframe {
                height: 320px !important;
              }
            }

            &--content {
              margin:2em 20px 4em;
            }
        }

      ul li {
        width: 50%;
      }

      &__each {
        margin-bottom: 35px;

        &--title {
          h1 {
            font-size: calc-em(28px);
          }

          span {
            font-size: calc-em(20px);
            font-weight: $semibold;
          }
        }
        .happenings__each--content {
          padding: 15px 20px;
        }
      }
      
    }
  
  }
  
  
  @include mobile {
  
  
    .happenings {
      padding: 30px 0;
      &__each--content {
        padding: 10px;
      }

      &__each {

        .happenings__each--content {
          background-color: $gold2;
  
          transition: background-color 0.5s;
        }
  
        .happenings__each--title:after {
            background-color: $dark-grey;
            opacity: .5;
        }
  
        .happenings__each--title span {
          color: $dark-grey;
          opacity: 0.5;
        }
      }

      ul li {
        width: 100%;
        float: none;
      }
  
    }
  
  }
  