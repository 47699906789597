@media only screen and (min-width: $bpMobile), print {
    [class^='col-'],
    [class*=' col-'] {
      width: 100%;
  
      align-items: flex-start;
    }
  
    .reverse-col-flow {
      [class^='col-'],
      [class*=' col-'] {
        align-items: flex-end;
      }
    }
  
    .row-col-12 {
      display: flex;
  
      width: 100%;
  
      flex-flow: row wrap;
  
  
      @for $i from 1 through 12 {
        .col-#{$i}-xs {
          width: (100% / 12 * $i);
        }
  
        .offset-#{$i}-xs {
          margin-left: (100% / 12 * $i);
        }
      }
    }
  
    .col-md-offset-4 {
      margin-left: 33.3333333%;
    }
  
    // .row-col-15 {
    //   width: 100%;
  
    //   @for $i from 1 through 15 {
    //     .col-#{$i}-xs {
    //       width: (100% / 15 * $i);
    //     }
  
    //     .offset-#{$i}-xs {
    //       margin-left: (100% / 15 * $i);
    //     }
    //   }
    // }
  
    .push-left-5 {
      padding-left: 5px;
    }
    .push-left-10 {
      padding-left: 10px;
    }
    .push-left-15 {
      padding-left: 15px;
    }
    .push-left-20 {
      padding-left: 20px;
    }
    .push-left-25 {
      padding-left: 25px;
    }
  
    .push-right-5 {
      padding-right: 5px;
    }
    .push-right-10 {
      padding-right: 10px;
    }
    .push-right-15 {
      padding-right: 15px;
    }
    .push-right-20 {
      padding-right: 20px;
    }
    .push-right-25 {
      padding-right: 25px;
    }
  }
  
  @media only screen and (min-width: $bpTablet), print {
    .row-col-12 {
      @for $i from 1 through 12 {
        .col-#{$i}-sm {
          width: (100% / 12 * $i);
        }
  
        .offset-#{$i}-sm {
          margin-left: (100% / 12 * $i);
        }
      }
    }
  
    // .row-col-15 {
    //   width: 100%;
  
    //   @for $i from 1 through 15 {
    //     .col-#{$i}-sm {
    //       width: (100% / 15 * $i);
    //     }
  
    //     .offset-#{$i}-sm {
    //       margin-left: (100% / 15 * $i);
    //     }
    //   }
    // }
  }
  
  
  @media only screen and (min-width: $bpDesktop), print {
    .row-col-12 {
      @for $i from 1 through 12 {
        .col-#{$i}-md {
          width: (100% / 12 * $i);
        }
  
        .offset-#{$i}-md {
          margin-left: (100% / 12 * $i);
        }
      }
    }
  
    // .row-col-15 {
    //   @for $i from 1 through 15 {
    //     .col-#{$i}-md {
    //       width: (100% / 15 * $i);
    //     }
  
    //     .offset-#{$i}-md {
    //       margin-left: (100% / 15 * $i);
    //     }
    //   }
    // }
  }
  