// button styles
.button {
    text-decoration: none;

    padding: 15px 40px;

    border-radius: 50px;
    min-width: 200px;

    display: inline-block;

    border: 2px solid $white;

    span {
        color: $white;
        letter-spacing: 1px;
        font-size: calc-em(14px);
    }

    &__opaque {
        background-color: $gold;

        border: none;

        transition: filter .5s;

        &:hover {

            filter: brightness(1.1);

            transition: filter .5s;
                
        }

    }
    &__dark {
        background-color: #333333;
        border: none;

        transition: filter .5s;

        &:hover {

            filter: brightness(1.1);

            transition: filter .5s;
                
        }
    }


}

@include mobile-tablet {
    .button {
        min-width: auto;
        padding: 15px 40px;

        span {
            font-size: calc-em(16px);
        }
    }
}