@mixin input-design {
  background: transparent;

  color: $dark-grey;

  min-height: 49px;
  font-size: calc-em(16px);
  padding-left: 17px;

  &::placeholder {
    color: $dark-grey;
  }
}

@mixin button-design {
  background-color: $dark-grey;
  border: transparent;

  padding: 15px 25px 15px 40px;

  border-radius: 25px;

  cursor: pointer;

  display: flex;
  align-items: center;

  &:hover {
    i {
      transform: translate(7px);

      transition: transform 0.5s;
    }
  }

  span {
    color: white;
  }

  i {
    color: $gold;

    padding-left: 15px;

    font-size: 1.2em;

    transform: translateX(0);

    transition: transform 0.5s;
  }
}

@mixin font-family {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

@mixin line-style {
  position: absolute;

  height: 2px;
  width: 100%;

  background-color: $gold;
  left: 0;
}

@mixin box-shadow($left, $top, $blur, $spread, $color, $inset: "") {
  -webkit-box-shadow: $left $top $blur $spread $color #{$inset};
  box-shadow: $left $top $blur $spread $color #{$inset};
}

@mixin rotate($deg) {
  transform: rotate($deg);
}

@mixin vertical-align {
  position: absolute;
  top: 50%;

  transform: translateY(calc(-50% - 0.5px));

  -webkit-backface-visibility: hidden;
}

@mixin plainlist() {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

@mixin screen($opacity, $color) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: $opacity;
  background: $color;
}

@mixin centering-the-unknown() {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 50%;
  margin: auto;

  transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px));
}

@mixin sprite($x, $y, $width, $height, $bgcolor: transparent) {
  display: inline-block;
  overflow: hidden;

  width: $width;
  height: $height;

  white-space: nowrap;
  text-indent: 100%;

  background: $bgcolor url("../images/sprite.png") no-repeat $x $y;

  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    background-image: url("../images/sprite-hd.png");
    background-size: 150px 150px;
  }
}

@mixin double-res-bg($height, $width, $path) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    background-image: url($path);
    background-size: $height $width;
  }
}

@mixin clearfix() {
  &:before,
  &:after {
    display: table;

    content: "";
  }

  &:after {
    clear: both;
  }
}

@mixin transition($type, $duration, $easing: linear) {
  /* Webkit */
  -webkit-transition-timing-function: $easing;
  transition-timing-function: $easing;
  -webkit-transition-duration: $duration;
  transition-duration: $duration;
  -webkit-transition-property: unquote($type);
  transition-property: unquote($type);

  -webkit-backface-visibility: hidden;
}

//CSS3 element scale
@mixin scale($scale: 1, $origin: center) {
  transform: scale($scale);

  @if ($origin != center) {
    @include origin($origin);
  }
}

@mixin background-cover($position1: center, $position2: center) {
  background-repeat: no-repeat;
  background-position: $position1 $position2;
  background-size: cover;
}

@mixin visuallyhidden() {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;
}

@mixin background-gradient($color1, $color2, $direction) {
  background: $color2;
  background-repeat: repeat-x;

  @if $direction == vertical {
    background: -ms-linear-gradient(bottom, $color1 0%, $color2 100%); /* IE10 Consumer Preview */
    background: -moz-linear-gradient(bottom, $color1 0%, $color2 100%); /* Opera */
    background: -o-linear-gradient(bottom, $color1 0%, $color2 100%);
    background: -webkit-gradient(
      linear,
      left bottom,
      left bottom,
      color-stop(0, $color1),
      color-stop(1, $color2)
    ); /* Webkit (Safari/Chrome 10) */
    background: -webkit-linear-gradient(top, $color1 0%, $color2 100%); /* Webkit (Chrome 11+) */
    background: linear-gradient(to top, $color1 0%, $color2 100%); /* W3C Markup, IE10 Release Preview */
  } @else if $direction == diagonal {
    background: -webkit-linear-gradient(left bottom, $color1, $color2); /* For Safari */
    background: -o-linear-gradient(top right, $color1, $color2); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top right, $color1, $color2); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to top right, $color1, $color2); /* Standard syntax (must be last) */
  } @else if $direction == horizontal {
    background: -webkit-linear-gradient(left, $color1, $color2); /* For Safari */
    background: -o-linear-gradient(right, $color1, $color2); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, $color1, $color2); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, $color1, $color2); /* Standard syntax */
  }
}

@mixin disable-select() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}

@mixin icomoon($glyph, $color: inherit, $pseudo-position: "before") {
  font-style: normal;
  font-variant: normal;

  text-transform: none;

  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if ($pseudo-position == "before") {
    &:before {
      font-family: "icomoon" !important;
      font-weight: normal;

      content: unquote('"') + unquote(str-insert($glyph, "\\", 1)) + unquote('"');

      color: $color;
    }
  } @else {
    &:after {
      font-family: "icomoon" !important;
      font-weight: normal;

      content: unquote('"') + unquote(str-insert($glyph, "\\", 1)) + unquote('"');

      color: $color;
    }
  }
}

@function calc-em($target, $context: $base-fontsize) {
  @return $target / $context * 1em;
}

// Calculate
@mixin calc-func($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

// https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;

    width: 100%;
    padding-top: percentage($height / $width);

    content: " ";
  }

  > .content {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: 0;
    padding: 0;
  }
}

@mixin mobile {
  @media (max-width: $bpTablet - 1) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $bpTablet) and (max-width: $bpDesktop - 1) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $bpDesktop) {
    @content;
  }
}

@mixin largedesktop {
  @media (min-width: $bpLgDesktop) {
    @content;
  }
}

@mixin giantscreen {
  @media (min-width: $bpGiantScreen) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: $bpDesktop - 1) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: $bpTablet) {
    @content;
  }
}

// Mixin for Objectfit
@mixin objectfit() {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

@mixin button($background: white, $color: $dirty-green) {
  padding: 12px 25px;
  outline: none;

  display: flex;
  align-items: center;

  border-radius: 50px;

  background-color: $background;
  border: 1px solid $color;
  color: $color;

  span {
    color: $color;
  }
}

// for import-conditions and pet-import-requirement
@mixin form-input-style {
  // height: 40px;
  min-width: 250px;
  min-height: 45px;
}

@mixin graceful-hide-show {
  visibility: hidden;
  opacity: 0;
  height: 0;

  overflow: hidden;

  &.show {
    visibility: visible;
    opacity: 1;
    height: auto;

    transition: opacity 0.3s;
  }
}

// Mixin for Objectfit
@mixin objectfit() {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;";
}

@mixin normalise-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
