// timeline styles

.timeline {
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    padding: 0px;
    list-style-type: none;

    &-section {
        position: relative;
        padding: 90px 0;
        &:before {
            top: 125px;
            bottom: 0;
            position: absolute;
            content: " ";
            width: 3px;
            background-color: #b99c66;
            left: 50%;
            margin-left: -1px;
        }
    }

    &-box {
        position: relative;
        float: left;
        clear: left;
        width: 45%;
        margin: 1em 2.5%;
        list-style-type: none;
        display: block;
        padding: 25px;
        background: #ececec;
        z-index: 1;

        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;

        &:hover:after {
            background: #b99c66;
        }

        span {
            position: absolute;
            top: -15px;
            right: 24px;
            font-size: 8em;
            font-weight: 700;
            color: #b99c66;
            opacity: 0.1;
            z-index: -1;
        }

        &:nth-child(2n):after {
            left: -6.8%;
            right: auto;
        }

        &:hover, &:focus {
            transform: translate(0, -5px);
            -webkit-transform: translate(0, -5px);
            -ms-transform: translate(0, -5px);
        }

        &:hover, &:focus {
            -webkit-box-shadow: 0px 4px 0px 0px #b99c66;
            -moz-box-shadow: 0px 4px 0px 0px #b99c66;
            box-shadow: 0px 4px 0px 0px #b99c66;
        }

        &:first-child {
            margin-bottom: 2.5em;
        }

        &:nth-of-type(1n):before {
            position: absolute;
            top: 15px;
            right: -10px;
            display: inline-block;
            border-top: 10px solid transparent;
            border-left: 10px solid #ececec;
            border-right: 0 solid #ececec;
            border-bottom: 10px solid transparent;
            content: " ";
        }

        &:nth-of-type(2n) {
            float: right;
            clear: right;
        }

        &:nth-of-type(2n):before {
            right: auto;
            left: -10px;
            position: absolute;
            top: 15px;
            display: inline-block;
            border-top: 10px solid transparent;
            border-right: 10px solid #ececec;
            border-left: 0 solid #ececec;
            border-bottom: 10px solid transparent;
            content: " ";
        }
    }

    &-title {
        color: #3c3c3c;
        font-weight: 700;
        font-size: calc-em(32px);
        float: left;
        margin: 0;
    }

    &-details {
        clear: both;
        padding-top: 5px;
    }

    
}

@media all and (max-width: 600px) {
    .timeline {
        &-box {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@media all and (min-width: 650px) {
    .timeline {
        &-box {

            &:after {
                display: block;
                content: ' ';
                height: 16px;
                width: 16px;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                right: -7.2%;
                top: 1.2em;
                border: 3px solid #b99c66;
            }
        }
    }
}


@media all and (min-width: 600px) {
    .timeline .timeline-box:nth-of-type(2n) {
        margin-top: 3em;
    }
}