// latest-projects styles
.latest-projects {
    padding: 75px 0 90px;

    background-color: $off-white;

    &.bg-white {
        background-color: white;
    }


    &__header {
        display: flex;
        flex-direction: column;

        align-items: center;

        margin-bottom: 38px;


        &:after {
            content: '';

            width: 50px;
            height: 1px;

            background-color: $black0;
        }

        h1 {
            margin: 0;

            font-family: $eb;
            font-size: calc-em(40px);

            font-weight: $medium;

            color: $dark-grey;

            margin-bottom: 10px;
        }

        p {
            margin: 0;

            font-family: $roboto;
            font-size: calc-em(17px);

            color: $silver;

            margin-bottom: 27px;
        }

    }


    &__slick {
        display: flex;
        flex-direction: row;

        .slick-arrow {
            border: 1px solid transparent;
            background: transparent;

            cursor: pointer;

            .icon {
                font-size: 3.5em;

                color: $dark-grey;
            }
        }

        .slick-list {
            margin-left: 2%;
            margin-right: 2%;
        }

    }


    &__project {
        padding-left: 12.5px;
        padding-right: 12.5px;

        text-decoration: none;

        color: $dark-grey;

        transition: color .5s;
        display: block;

        &:hover {
            color: $gold;

            transition: color .5s;


            .latest-projects__project--overlay {
                &:before{
                    opacity: 0;

                    transition: opacity 0.5s ease-in;
                }
                img {
                    opacity: 0;

                    transition: opacity 0.5s ease-in;
                }
            }


        }


        &--image {
            height: 284px;

            margin-bottom: 10px;

            position: relative;
        }

        &--overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                background-color: $black0;
                opacity: .47;

                transition: opacity 0.5s;
            }
            
            img {
                transition: opacity 0.5s;
            }
        }

        &--logo {
            z-index: 2;
        }

        &--details {
            padding-left: 15px;
            text-align: left;

            h1 {
                margin: 0;

                font-family: $eb;
                font-weight: $medium;

                font-size: calc-em(28px);

                margin-bottom: 5px;
            }

            span {
                font-family: $roboto;
                font-weight: $regular;

                font-size: calc-em(17px);
            }

        }
    }

    

}

@media only screen and (min-width: $bpTablet){
    .latest-projects {
        &-tiles {
            .latest-projects__project {
                display: inline-block;
                width: 33.33333%;
            }
        }
    }
}


@include mobile-tablet {

    .latest-projects {
        padding: 45px 0 50px;

        &__project {
            margin-bottom: 35px;

            &--details {
                padding-left: 0;
            }
        }

        &__header {
            text-align: center;
            margin-bottom: 25px;

            p {
                margin-bottom: 20px;
                font-size: calc-em(20px);
                line-height: 26px;
            }
        }
        &__slick {

            .slick-arrow {


                .icon {
                    font-size: 3em;
                }
                

            }

        }

        &__project--image {
            height: 225px;
        }

        &__project--overlay img {
            width: 140px;
        }

        &__project--details {
            h1 {
                font-size: calc-em(32px);
            }

            span {
                font-size: calc-em(20px);
            }
        }

    }

}
