// featured-projects styles
.featured-projects {


    .slick-arrow {
        position: absolute;
        right: 0;

        z-index: 1;

        top: 52%;

        width: 65px;
        height: 65px;

        border: 1px solid $silver;

        background-color: $dark-grey;

        cursor: pointer;

        &.slick-prev {
            right: 64px;
        }


        .icon {
            color: white;

            font-size: 2.2em;
        }

    }

    &__slick {


        &--each {
            display: flex;
            flex-direction: column;

            flex-wrap: wrap;


            > .container {
                display: flex;

                flex-wrap: wrap
            }

        }

        &--img {
            width: 100%;

            position: relative;

            height: 0;

            padding-top: 52%;


            img {
                position: absolute;
                left:0;
                right:0;
                top:0;
                bottom:0;
            }
        }


        &--details {
            padding-top: 35px;
            padding-bottom: 45px;
            flex-basis: 65%;
        }

        &--title {
            margin: 0;

            font-size: calc-em(34px);

            color: $dark-grey;

            display: flex;
            flex-direction: column;

            margin-bottom: 25px;


            span {
                // font-size: calc-em(20px);
                font-size: 18px;

                color: $silver;
                font-weight: $regular;
                margin-top: 5px;
            }

            &:after {
                content: '';

                width: 45px;
                height: 2px;

                background-color: $gold;

                margin-top: 23px;

            }

        }


        &--info {

            li {
                color: $silver;

                font-size: calc-em(18px);

                margin-bottom: 5px;



                label {
                    color: $dark-grey;
                }
            }

        }

    }

}

@include mobile {

    .featured-projects {

        &__slick {

            &--details {
                flex-basis: 100%;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            &--title {
                font-size: calc-em(32px);

                span {
                    // font-size: calc-em(20px);
                    font-size: 16px;
                }
            }
            

        }
        .slick-arrow.slick-prev {
            right: 49px;
        }
        .slick-arrow {
            width: 50px;
            height: 50px;
        }
        

    }

}