// standard-banner styles
.standard-banner {
  // padding: 50px 0 20px;

  // height: 225px;

  background-color: $off-white;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  &__container {
    width: 100%;
  }

  &__contact-us {
    .standard-banner__slick--each {
      min-height: 40vh;
    }
  }

  &.has-slick {
    background-color: transparent;
  }

  .breadcrumbs-wrapper {
    position: absolute;
    left: 0;
    right: 0;

    z-index: 3;

    top: 27px;

    width: 100%;
  }


  * {
    color: $dark-grey;
  }

  // > .container {
  //   position: relative;

  //   height: 100%;
  //   width: 100%;
  // }

  &.has-background {
    height: 465px;
    padding: 0;

    * {
      color: $white;
    }

    .standard-banner__slick--each {
      min-height: auto;
      background-position: center;

      &:after {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &.has-slick {
    .breadcrumbs a {
      color: $white;

      &:hover:after, &:focus:after {
        color: $white;
      }
    }

    * {
      color: $white;
    }
  }

  &__slick {
    height: 100%;

    width: 100%;

    position: relative;

    &--desc {
      font-size: calc-em(18px);
    }

    &--each {
      height: 100%;
      min-height: 80vh;
      display: flex !important;
      align-items: center;

      // padding-left: 10%;

      background-size: cover;
      background-repeat: no-repeat;

      @include mobile {
        min-height: auto;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.02);
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      background-color: transparent;

      border: none;

      cursor: pointer;

      z-index: 3;

      .icon {
        font-size: 4em;
      }
    }

    .slick-prev {
      left: 40px;
    }

    .slick-next {
      right: 40px;
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      display: flex;

      > div {
        width: 100%;
      }
    }

    .slick-dots {
      display: flex;
      justify-content: center;

      margin-top: -5%;

      li {

        &.slick-active {

            button:before {
                color: white;
            }

        }   

        button {
            position: relative;
            
            color: transparent;

            background: none;
            border: none;

          &:before {
            content: "•";
            font-size: 3em;
            color: rgba(255,255,255,.4);
            opacity: 1;
            cursor: pointer;

            display: flex;
          }

        }
      }
    }

    &--container {
      width: 100%;
      z-index: 2;

      h1 {
        font-size: calc-em(35px);

        display: flex;
        flex-direction: column;

        font-weight: $regular;

        margin: 0;
        margin-bottom: 34px;

        span {
          font-weight: $bold;
          font-size: calc-em(35px);
          font-family: $eb;
        }
      }
    }
  }

  &__title {
    font-family: $eb;

    font-size: calc-em(60px);

    font-weight: $semibold;

    margin: 0;
    margin: 60px 0 25px;
    // position: absolute;
    // top: 40%;
    // transform: translateY(-50%);
  }
}

@media only screen and (min-width: $bpDesktop) {
  .standard-banner__slick .slick-prev {
      left: 20px;
  }

  .standard-banner__slick .slick-next {
      right: 20px;
  }

  .standard-banner__slick--container {
    // padding: 0 80px;
  }
}

@media only screen and (max-width: $bpLgDesktop - 1) {
  .standard-banner__slick--container {
    padding: 0 80px;
  }
}


@include mobile-tablet {
  .standard-banner {
    
    &__slick {

      &--container {
        padding-left: 55px;
          padding-right: 55px;

        h1 span {
          font-size: calc-em(22px);
        }
        h1 {
          margin-bottom: 20px;
        }
      }

      &--desc {
        font-size: calc-em(22px);
        margin: 0;
      }

      .slick-arrow {
        font-size: .75em;
      }

      .slick-prev {
        left: 5px;
      }

      .slick-next {
        right: 5px;
      }

    }

    &__title {
      font-size: calc-em(46px);
      margin: 35px 0 0;
    }

  }
}

@include mobile {
  .standard-banner {
    height: 135px;

    &.has-slick {
      height: 320px;
    }


    &__slick {
      height: 100%;

      .slick-arrow {
        font-size: .5em;
        display: none !important;
      }


      .slick-dots {
        margin-top: 0%;

        position: absolute;

        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
      }

      &--container {
        padding-left: 15px;
        padding-right: 15px;
      }

    }

    &.has-background {
      height: 400px;
    }
    
  }
  
  
}

.banner-with-overlay {
  position: relative;
  
  .standard-banner__slick--each {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .standard-banner__slick  {
    z-index: 1;
  }
}
