// related-card styles
.related-card {

    flex-basis: calc(33% - 17.5px);

    text-decoration: none;

    color: $dark-grey;


    &__details {

        &--title {

            margin: 0;

            font-family: $eb;

            font-size: calc-em(24px);
            

            margin-bottom: 5px;
        }

        &--desc {
            margin: 0;

            font-size: calc-em(16px);

            color: $silver;
        }

    }


    &__image {

        overflow: hidden;

        margin-bottom: 13px;


        img {
            transform: scale(1);

            transition: transform .5s;
        }

    }

    &:hover {


        .related-card__image img {
            transform: scale(1.2);

            transition: transform .5s;
        }

    }

}


@include mobile {

    .related-card {
        flex-basis: 100%;

        margin-bottom: 25px;


        &:last-of-type {
            margin-bottom: 0;
        }
 
        &__image {
            margin-bottom: 10px;
        }

        &__details--title {
            font-size: calc-em(24px);
        }

    }


}