// gallery styles

.gallery {
    // border-top: 1px solid $silver;
    // border-bottom: 1px solid $silver;
    padding: 5px 0 35px;
    margin: 35px 0 30px;
    // background: #ececec;

    &__title {
        font-weight: $regular;

        font-size: calc-em(28px);
        margin-bottom: 35px;
        color: $dark-grey;
    }

    &-list {
        margin: 0 -5px;
        &__item {
            padding: 0 5px;
            outline: 0;
            position: relative;
            overflow: hidden;

            img {
                transition: transform .25s $easeInOutMotion;
            }

            &:hover, &:focus {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__image {
            overflow: hidden;
            display:block;
        }

        .slick-track {
            margin: 0;
        }
    }
    &-results {
        font-size: calc-em(28px);
        font-weight: $semibold;
        color: $white;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: calc(100% - 5px);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        text-shadow: 0px 1px 5px $silver;
        
        &:after {
            background: transparentize($black0, .55);
            content: '';
            display: block;
            height: 100%;
            width: calc(100% - 5px);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            margin: 0 auto;
        }

        &.hidden {
            opacity: 0;
        }
    }
}

@media only screen and (max-width: $bpTablet) {
    .gallery-list {
        &__item {
            img {
                @include objectfit();
            }
        }
    }
}