// form-modal styles
.form-modal {
  display: none;

  background-color: rgba(0, 0, 0, 0.85);

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 999;

  height: 100vh;
  width: 100vw;

  &.is-visible {
    display: flex;

    align-items: center;
    justify-content: center;
  }

  &__modal {
    width: 40%;
    height: auto;

    overflow: hidden;

    position: relative;

    color: white;

    &--video {
      position: absolute;
      left: 0;
      right:0;
      top:0;
      bottom:0;
    }

    &--title {
      font-family: $eb;

      margin: 0;

      font-size: calc-em(60px);

      letter-spacing: .02em;

      margin-bottom: 35px;

      font-weight: $regular;

      text-align: center;

      line-height: 1.1;
    }

    &--desc {
      font-family: $eb;

      margin-bottom: 30px;

      text-align: center;
    }


    a {
      background-color: $gold;

      border: none;
    }


    &--content {
      position: relative;
      z-index: 4;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 100%;

      // padding-left: 30%;
      // padding-right: 30%;

      img {
        margin-bottom: 35px;
      }

    }


    &--close {
      position: absolute;
      top: 0;
      right: 0;

      border-radius: 0;

      border: none;

      background-color: $gold;

      color: white;

      height: 45px;
      width: 45px;

      cursor: pointer;

      z-index: 6;

      .icon {
        font-size: 1.2em;
      }

    }

  }

  &__half {

    &--content {
      background: white;
      color: $dark-grey;
      padding: 40px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-family: $eb;
        font-size: calc-em(32px);
        font-weight: $bold;
        margin: 15px 0 15px;
      }

      h3 {
        font-size: calc-em(20px);
        margin: 0 0 5px;
        color: $brown;
        font-weight: $regular;
        line-height: 32px;
      }
    }

    &--icon {
      position: relative;
      margin: 25px 0 20px;
      .icon {
        font-size: 26px;
        line-height: 26px;
        color: $gold;
        position: relative;
        z-index: 1;
        background: white;
        display: inline-block;
        padding: 0 10px;
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height:1px;
        background: $gold;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 0;
      }
    }

    &--footer {
      p {
        margin-bottom: 5px;
        color: $dark-grey;
        font-weight: $medium;
      }
    }
  }
}


@include mobile {

  .form-modal {

    &__modal {
      width: 90%;
      height: auto;

      &--content {
        padding-left: 5%;
        padding-right: 5%;

        img {
          max-width: 55%;

          margin-bottom: 35px;
        }

      }

      &--title {
        font-size: calc-em(40px);
      }

    }
    
    &__half {
      display:block;
      &--image{
        display:block;
        width: 100%;
        height: 280px;
      }
      &--content {
        display:block;
        width: 100%;
        padding: 25px 30px;
      }
    }

  }

}
