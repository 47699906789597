// custom-select styles
.custom-select {
    label {
        margin-bottom: 13px;
        display: block;
    }
    &__label {
      color: $dark-grey;
      font-size: calc-em(14px);
      font-weight: $medium;
      margin-right: 10px;
    }
  
    select {
      @include visuallyhidden();
    }
  
    &__custom-dropdown {
      display: block;
      position: relative;
    //   min-width: 200px;
  
      &.list-shown {
        .custom-list {
          opacity: 1;
          transform: scale(1);
          visibility: visible;
        }
      }
  
      .custom-trigger {
        background-color: transparent;
        border: 1px solid #000000;
        padding: 15px 25px 15px 17px;
        border-radius: 0;
        color: $black0;
        font-size: calc-em(16px);
        position: relative;
        text-align: left;
        width: 100%;
  
        &:hover, &:focus {
          background: $gold2;
        }
  
        .icon {
          align-items: center;
          display: flex;
          color: $black0;
          font-size: 1.2em;
          height: 100%;
          justify-content: center;
          position: absolute;
          width: 40px;
          right: 0;
          text-align: center;
          top: 0;
        }
        span {
            color: $dark-grey !important;
        }
      }
  
      .custom-list {
        @extend %list-unstyled;
        
        max-height: 350px;
        opacity: 0;
        overflow: auto;
        position: absolute;
        // MOZ
        scrollbar-color: $dark-grey $gold2;
        scrollbar-width: thin;
        // IE 11
        scrollbar-face-color: $dark-grey;
        scrollbar-track-color: $gold2;
        transform: scale(0.5);
        transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
        visibility: hidden;
        width: 100%;
        z-index: 999;
        border: 1px solid #000000;
        background: $gold2;
        border-radius: 0;
  
        &::-webkit-scrollbar {
          width: 5px;
          background: $gold2;
        }
  
        &::-webkit-scrollbar-thumb {
          background: $dark-grey;
          border-radius: 5px;
        }
  
        li {
          &.active {
            button {
              background: $dark-grey;
              color: #fff;
            }
          }
  
          button {
            background: transparent;
            border: 0;
            border-radius: 0;
            display: block;
            font-size: calc-em(16px);
            padding: 10px;
            text-align: left;
            width: 100%;
  
            &:hover, &:focus {
              background: $dark-grey;
              color: #fff;
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: $bpTablet - 1) {
    .custom-select {
      &__label {
        @include visuallyhidden();
      }
  
      &__custom-dropdown {
        display: block;
      }
    }
  }
  