@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/fonts/icomoon.eot?ybxbmr');
    src:  url('../fonts/fonts/icomoon.eot?ybxbmr#iefix') format('embedded-opentype'),
      url('../fonts/fonts/icomoon.ttf?ybxbmr') format('truetype'),
      url('../fonts/fonts/icomoon.woff?ybxbmr') format('woff'),
      url('../fonts/fonts/icomoon.svg?ybxbmr#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.icon-close:before {
  content: "\e906";
}
.icon-location:before {
  content: "\e947";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-angle-up:before {
  content: "\e900";
}
.icon-angle-right:before {
  content: "\e901";
}
.icon-angle-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e904";
}
.icon-angle-down:before {
  content: "\e905";
}
