// contact-details styles
.contact-details {

    background-color: $black1;

    padding-top: 75px;
    padding-bottom: 60px;

    > .container {
        display: flex;
    }


    &__each {
        padding-right: 107px;

        color: $dark-grey;


        &--header {
            margin: 0;

            text-transform: uppercase;

            font-size: calc-em(20px);

            font-weight: $bold;

            margin-bottom: 50px;

            letter-spacing: .05em;

            white-space: nowrap;

            position: relative;

            &:after {
                content: '';
                position: absolute;

                background-color: $gold;

                width: 45px;
                height: 2px;

                bottom: -20px;
                left: 0;
            }

        }

        &--title {
            font-weight: $bold;

            font-size: calc-em(20px);

            margin-bottom: 10px;

            display: inline-block;
        }

        &--text {
            margin: 0;

            margin-bottom: 20px;
            
        }

        a {
            // color: $black1;
            color: black;
            text-decoration: none;
    
            &:hover {
                text-decoration: underline;
            }
    
        }

    }
}

@include mobile {

    .contact-details {
        padding-top: 35px;
        padding-bottom: 10px;
        > .container {
            flex-direction: column;
        }


        &__each {
            margin-bottom: 10px;

            &--header {
                margin-bottom: 25px;

                &:after {
                    bottom: -10px;
                }
            }
        }

    }

}