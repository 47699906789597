// site-header styles
.site-header {
  
  padding: 35px 0;

  transition: background-color .3s ease-out, max-height 0.5s cubic-bezier(0, 1, 0, 1);
  transition: all 500ms ease;
  max-height: 200px;

  display: flex;

  &__details-page, &__project-page {
    padding: 20px 0;

  }

  #logo {
    display: none;
  }

  #logoWhite {
    display: flex;
  }


  &.is-fixed {
    background: white !important;

    // transition: background-color .3s ease-in;

    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    z-index: 999;

    -webkit-box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.1);

    max-height: 90px;

    // transition: max-height .3s ease-in;

    // overflow: hidden;


    .site-header__logo {
      max-width: 85px;

      img {
        @include objectfit;
      }
    }


    #logo {
      display: none !important;
    }

    #logoWhite {
      display: flex !important;
    }

  }

  // only show in mobile
  &__mobile {
    display: none;
  }

  .container {
    position: static;

    align-items: center;

    width: 100%;
  }

  .navigation__lvl1--link {
    color: $dark-grey;
  }

  .site-header__social a {
    color: $dark-grey;
  }

  &__home {
    background: linear-gradient(180deg, #020818 , transparent);

    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    z-index: 1;

    #logo {
      display: flex;
    }

    #logoWhite {
      display: none;
    }

    .navigation__lvl1--link {
      color: $white;
    }

    .site-header__social a {
      color: $white;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  // hide the hamburger button
  &__hamburger--wrapper {
    display: none;
  }

  &__logo {
    max-width: 112px;
    transition: all 500ms ease;
  }

  // houses the navigation module
  &__navigation {
    display: flex;
    align-items: center;

    // padding-left: 10%;
  }

  &__social {
    display: flex;
    align-items: center;

    a {
      color: $white;
      transition: color 0.5s;

      text-decoration: none;

      margin: 0 10px;

      i {
        font-size: 1.2em;
      }

      &:hover {
        color: $gold;

        transition: color 0.5s;
      }
    }
  }

}

@include mobile-tablet {
  .site-header {
    padding: 15px 0;

    // show mobile elements above navigation
    &__mobile {
      display: flex;
      justify-content: space-between;

      width: 100%;

      padding: 25px;

      &--social {
        a {
          text-decoration: none;
          color: $gold;

          margin-right: 20px;

          font-size: 24px;
        }
      }

      &--close {
        background: transparent;

        border: none;

        color: $gold;

        font-size: 1.8em;

        padding-right: 0;

        cursor: pointer;
      }
    }

    .navigation__lvl1--link {
      color: $gold;
    }

    // &__home {

    // }

    &__logo {
      max-width: 90px;
    }

    // this is a button
    &__hamburger {
      position: relative;

      // reverse button style
      background-color: transparent;
      border: none;

      cursor: pointer;

      width: 30px;
      height: 25px;

      &--wrapper {
        display: flex;
        align-items: center;
      }

      span {
        top: 9px;

        @include line-style;

        opacity: 1;

        border-radius: 2px;

        &:before {
          content: "";

          top: -8px;
          z-index: 3;

          @include line-style;

          transform: rotate(0deg);
          transform-origin: left center;

          transition: transform 0.3s ease-out;
        }

        &:after {
          content: "";

          top: 8px;

          z-index: 3;

          @include line-style;

          transform: rotate(0deg);
          transform-origin: left center;

          transition: transform 0.3s ease-out;
        }
      }
    }

    &__navigation {
      opacity: 0;
      visibility: hidden;

      padding-left: 0;

      width: 100%;

      position: absolute;
      left: 0;
      top: 0;

      z-index: 4;

      align-items: flex-start;
      flex-direction: column;

      &.show {
        opacity: 1;
        visibility: visible;

        height: 100vh;

        background-color: $brown;
      }
    }

    &__social {
      display: none;
    }

    &__microsite {
      .site-header__navigation.show, .navigation {
        background-color: $darkblue;
      }
    }
  }
}
