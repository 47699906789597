// back-to-top styles
.back-to-top {
  opacity: 0;
  visibility: hidden;

  cursor: pointer;

  position: fixed;

  // to change these values
  right: 30px;
  bottom: 30px;

  transition: opacity .5s ease-in-out;

  z-index: 6;



  img {
    width: 70px;
  }

  .icon {
    background: $gold;
    border-radius: 40px;
    padding: 10px 21px 12px 20px;
    font-size: 30px;
    display: inline-block;
    transition: all 500ms ease;

    &:before {
      transform: rotate(-90deg);
      display: inline-block;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;

    transition: opacity .5s ease-in-out;
  }

  &:hover {
    .icon {
      color: $gold;
      background: $black0;
    }
  }

}

@include mobile {
  .back-to-top {
    right: 20px;
    bottom: 60px;
    .icon {
      padding: 10px 16px;
      font-size: 21px;
      line-height: 20px;
    }
  }
}
