  %list-unstyled {
    @include normalise-list;
  }

  %clearfix {
    @include clearfix;
  }
  
  .clearfix {
    @extend %clearfix;
  }
  
  %background-cover {
    @include background-cover;
  }
  
  %visuallyhidden {
    @include visuallyhidden;
  }
  
  .visuallyhidden {
    @extend %visuallyhidden;
  }
  
  .no-desktop {
    @include desktop {
      display: none!important;
    }
  }
  
  .no-mobile {
    @include mobile {
      display: none!important;
    }
  }
  
  .desktop-only {
    @include mobile {
      display: none!important;
    }
    @include tablet {
      display: none!important;
    }
  }
  
  .handheld-only {
    @include desktop {
      display: none!important;
    }
  }
  
  
  .no-padding {
    padding: 0 !important;
  }
  
  .no-padding-right {
    padding-right: 0 !important;
  }
  
  .no-padding-left {
    padding-left: 0 !important;
  }
  
  .no-padding-top {
    padding-top: 0 !important;
  }
  
  .no-padding-bottom {
    padding-bottom: 0 !important;
  }
  
  
  @include mobile {
    .no-padding--mbl {
      padding: 0 !important;
    }
    .no-padding-right--mbl {
      padding-right: 0 !important;
    }
    .no-padding-left--mbl {
      padding-left: 0 !important;
    }
  
    .no-padding-top--mbl {
      padding-top: 0 !important;
    }
  
    .no-padding-bottom--mbl {
      padding-bottom: 0 !important;
    }
  }
  
  @include tablet {
    .no-padding--tbt {
      padding: 0 !important;
    }
    .no-padding-right--tbt {
      padding-right: 0 !important;
    }
    .no-padding-left--tbt {
      padding-left: 0 !important;
    }
  
    .no-padding-top--tbt {
      padding-top: 0 !important;
    }
  
    .no-padding-bottom--tbt {
      padding-bottom: 0 !important;
    }
  }
  
  
  @include desktop {
    .no-padding--dsk {
      padding: 0 !important;
    }
  
    .no-padding-left--dsk {
      padding-left: 0 !important;
    }
  
    .no-padding-right--dsk {
      padding-right: 0 !important;
    }
  
    .no-padding-top--dsk {
      padding-top: 0 !important;
    }
  
    .no-padding-bottom--dsk {
      padding-bottom: 0 !important;
    }
  }
  