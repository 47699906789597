.career-listing {
  padding: 40px 0px;
  background-color: #f7f7f7;
  .listing-container {
    display: flex;
    flex-wrap: wrap;
  } 
  .career-listing-item {
    display: block;
    padding: 10px 20px;
    width: calc(100%/3);
    padding-bottom: 40px;
    text-align: left;
    display: block;
    position: relative;
    overflow: hidden;
    .career-listing-item-wrapper {
      height: 100%;
      background-color: white;
      padding: 20px 20px;
      display:  flex;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: antiquewhite;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #f7f7f7;
        z-index: 1;
        top: 0;
        left: 0;
        transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      
    }
    &.showing {
      .career-listing-item-wrapper {
        &::after {
          transform: translateY(-110%);
        }
      }
    }
    
    @media only screen and (min-width: $bpMobile) {
      width: 100%;
    }

    @media only screen and (min-width: $bpTablet) {
      width: calc(100%/2);
    }
    
    @media only screen and (min-width: $bpDesktop) {
      width: calc(100%/3);
    }
    
    
    p {
      font-size: 0.9rem;
      &.title {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        margin-bottom: 25px;
      }
    }
    
    .description {
      display: block;
      flex: 1;
    }
    .button {
      padding: 7px 20px;
      text-align: center;
      min-width: 150px
    }



  }
}